import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBDatatable } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { handleError } from "../../../utils/utils";
import NodeService from "../../service/node.service";
import NodeGroupService from "../../service/nodeGroup.service";
import TokenService from "../../service/token.service";
import xssFilterService from "../../service/xssfilter.service";
import NodeGroupTag from "./NodeGroupTag";

const EditNodeGroup = ({
  nodeGroupEditActiveModal,
  open,
  selectNodeGroup,
  handleCompleteSubmitEdit,
}) => {
  const [activeEditModal, setActiveEditModal] = nodeGroupEditActiveModal;
  const [openEdit, setOpenEdit] = open;
  const [tagsData, setTagsData] = useState([]);
  const [nodesInGroup, setNodesInGroup] = useState([]);
  const [actionData, setActionData] = useState(null);
  const [displayNodeInGroup, setDisplayNodeInGroup] = useState([]);
  const [openDataTable, setOpenDataTable] = useState(false);
  const [recordDeleteNode, setRecordDeleteNode] = useState(new Set());
  const [changeGroupName, setChangeGroupName] = useState("");
  const [sendTagData, setSendTagData] = useState([]);
  const [deleteNodeArray, setDeleteNodeArray] = useState([]);
  const [groupNodeLoadDone, setGroupNodeLoadDone] = useState(false);
  const firstUpdate = useRef(true);
  const manageNodeTab = useRef(null);
  const history = useHistory();

  useEffect(() => {
    setChangeGroupName(selectNodeGroup.name);
    const handleGetNodeList = () => {
      NodeService.getNodeList(TokenService.getToken())
        .then((response) => {
          console.log(response.data.nodes);
          // let nodeidList = response.data.nodes.map((item) => item._id);
          let node = JSON.parse(JSON.stringify(response.data.nodes));
          if (node.length === 0) {
            setGroupNodeLoadDone(true);
          }

          handleGetNodeDetail(response.data.nodes);
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, handleGetNodeList);
        });
    };
    handleGetNodeList();
  }, []);

  const handleGetNodeDetail = (nodelist) => {
    NodeService.getNodeArray(TokenService.getToken(), nodelist)
      .then((response) => {
        console.log(response.data);
        let nodeList = response.data;

        let resultGroup = nodeList.filter((node) =>
          node.groups?.includes(selectNodeGroup._id)
        );

        setNodesInGroup(resultGroup);
        setGroupNodeLoadDone(true);
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          handleGetNodeDetail(nodelist);
        });
      });
  };

  useEffect(() => {
    // console.log(nodesInGroup);
  }, [nodesInGroup]);

  useEffect(() => {
    // console.log(groupNodeLoadDone);
  }, [groupNodeLoadDone]);

  const handleCloseEditGroupWindow = (e) => {
    setActiveEditModal(false);
    setOpenEdit(false);
  };

  const handleTags = (e) => {
    setTagsData(e);
  };

  const handleManageNodeTab = () => {
    let objArray = [];
    for (let i = 0; i < nodesInGroup.length; i++) {
      let location = "";
      if (nodesInGroup[i].desc.location === undefined) {
        location = nodesInGroup[i].desc.loc;
      } else {
        location = nodesInGroup[i].desc.location;
      }

      let obj = {
        id: nodesInGroup[i]._id,
        nodename: nodesInGroup[i].name,
        org: nodesInGroup[i].desc.org,
        suborg: nodesInGroup[i].desc.sub_org,

        location: location,
      };
      objArray.push(obj);
    }

    setDisplayNodeInGroup(objArray);
  };

  useEffect(() => {
    setOpenDataTable(true);
  }, [actionData]);

  const handleDelete = (row) => {
    recordDeleteNode.add(row);
    let tempArray = JSON.parse(JSON.stringify(displayNodeInGroup));

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].id === row.id) {
        tempArray.splice(i, 1);

        break;
      }
    }

    setDisplayNodeInGroup(tempArray);
  };

  useEffect(() => {
    setActionData({
      columns: [
        { label: "", field: "order" },
        { label: "Node Name", field: "nodename" },
        { label: "Org", field: "org" },
        { label: "Sub Org", field: "suborg" },
        { label: "Location", field: "location" },

        { label: "Delete", field: "delete", sort: false },
      ],
      rows: displayNodeInGroup.map((row, order) => {
        return {
          ...row,
          order: <div>{order + 1}</div>,

          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit edit-nodeGroup-dbtn"
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDelete(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#25AFA8"
                  className="bi bi-trash"
                  viewBox="0 0 16 16 "
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });
  }, [displayNodeInGroup]);

  const handleSubmitNodeGroup = () => {
    if (xssFilterService.containSpecialChar(changeGroupName)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Group Name cannot contain special characters!",
      });
      return;
    }

    if (changeGroupName.trim().length < 2) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Enter A Valid Name!",
      });
      return;
    }

    setDeleteNodeArray(Array.from(recordDeleteNode));

    let tempTagArray = [];
    for (let i = 0; i < tagsData.length; i++) {
      tempTagArray.push(tagsData[i].id);
    }
    setSendTagData(tempTagArray);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      NodeGroupService.editNodeGroup(
        TokenService.getToken(),
        selectNodeGroup._id,
        changeGroupName,
        sendTagData
      )
        .then((response) => {
          console.log(response);

          if (deleteNodeArray.length > 0) {
            for (let i = 0; i < deleteNodeArray.length; i++) {
              NodeGroupService.removeNodeFromGroup(
                TokenService.getToken(),
                selectNodeGroup._id,
                deleteNodeArray[i].id
              )
                .then((response) => {
                  // console.log(response);
                  handleCompleteSubmitEdit("Edit Done");
                  handleEditGroupSuccess();
                })
                .catch((error) => {
                  // console.log(error.response);
                  handleError(error, history);
                });
            }
          } else {
            handleCompleteSubmitEdit("Edit Done");
            handleEditGroupSuccess();
          }
        })
        .catch((error) => {
          // console.log(error.response);
          handleEditGroupFail();
          handleError(error, history);
        });
    }
  }, [sendTagData]);

  const handleChangeNodeGroupName = (e) => {
    setChangeGroupName(xssFilterService.removeHtmlTags(e.target.value));
  };

  const handleEditGroupSuccess = () => {
    swal("Success!", "Edit Node Group Success!", "success");
  };

  const handleEditGroupFail = () => {
    swal("Error!", "Edit Node Group Fail", "error");
  };

  const handleSaveEditGroupInfo = () => {
    manageNodeTab.current.click();
  };

  return (
    <>
      <Modal
        show={activeEditModal}
        onHide={handleCloseEditGroupWindow}
        className="fade bd-example-modal-lg"
        id="addOrg"
        size="lg"
      >
        <Modal.Body className="card-header-tab">
          <div className="card">
            <Tab.Container defaultActiveKey="groupname">
              <div className="card-tab-shadow">
                <div className="card-header d-sm-flex d-block pb-0 border-0 card-header-tabtitle">
                  <Button
                    variant=""
                    className="close close-intab close-lg-display"
                    onClick={() => handleCloseEditGroupWindow()}
                  >
                    <span>&times;</span>
                  </Button>
                  <div className="">
                    <h4 className="header-title fs-20 mt-2 ">
                      Edit Node Group
                    </h4>
                  </div>
                  <div className="card-action  card-tabs4  ">
                    <Nav as="ul" className="nav nav-tabs" role="tablist">
                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="nav-item-tab"
                          data-toggle="tab"
                          eventKey="groupname"
                        >
                          Group Name
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item className="nav-item">
                        <Nav.Link
                          id="edit-group-manage-nodes"
                          className="nav-item-tab"
                          eventKey="adduser"
                          onClick={(e) => handleManageNodeTab(e)}
                          ref={manageNodeTab}
                          disabled={!groupNodeLoadDone}
                        >
                          Manage Nodes
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Button
                    variant=""
                    className="close close-intab ml-5 close-btn-position"
                    onClick={() => handleCloseEditGroupWindow()}
                  >
                    <span>&times;</span>
                  </Button>
                </div>
              </div>

              <div className="">
                <Tab.Content className="tab-content">
                  <Tab.Pane role="tabpanel" eventKey="groupname">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="featured-menus owl-carousel">
                        <div className="items mt-2">
                          <div className="form-group  px-5 ">
                            <div className="form-group">
                              <label className="normal-text mt-3 ml-1">
                                Node Group Name
                              </label>
                              <input
                                type="text"
                                className="form-control mb-3"
                                value={changeGroupName}
                                onChange={(e) => handleChangeNodeGroupName(e)}
                                maxLength="50"
                                minLength="2"
                                required
                              />
                              <label className="normal-text mt-4 ml-1">
                                Tags
                              </label>

                              <NodeGroupTag
                                nodeGroup={selectNodeGroup}
                                dealTags={handleTags}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-button-edit-ngroup">
                        <input
                          id="add-group-basicinfo-btn"
                          className="btn btn-primary input-submit-btn  ml-3"
                          type="submit"
                          value="Save & Next"
                          onClick={
                            changeGroupName === ""
                              ? () => {}
                              : () => handleSaveEditGroupInfo()
                          }
                        />
                      </div>
                    </form>
                  </Tab.Pane>

                  <Tab.Pane role="tabpanel" eventKey="adduser">
                    <div className="featured-menus owl-carousel ">
                      <div className="edit-node-group">
                        {openDataTable && (
                          <MDBDatatable
                            hover
                            data={actionData}
                            advancedData
                            entries={8}
                            entriesOptions={[8, 16, 24]}
                          />
                        )}
                      </div>

                      <div className="d-flex tab-content-end">
                        <Button
                          variant=""
                          type="button"
                          className="btn btn-submit btn-primary ml-4 px-4 mt-2 info-btn"
                          onClick={() => handleSubmitNodeGroup()}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditNodeGroup;
