import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBDatatable } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { handleError } from "../../utils/utils";
import TokenService from "../service/token.service";
import UserService from "../service/user.service";

const ModifyUserNodeGroup = ({
  nodeGroupWindow,
  openModifyNode,
  userGroupName,
  userGroupID,
  nodeGroupList,
}) => {
  const [activeNodeGroupWindow, setActiveNodeGroupWindow] = nodeGroupWindow;
  const [openModifyNodeGroup, setOpenModifyNodeGroup] = openModifyNode;
  const [userGroupsNodeGroup, setUserGroupsNodeGroup] = nodeGroupList;
  const [selectUserGroupName, setSelectUserGroupName] = userGroupName;
  const [selectUserGroupID, setSelectUserGroupID] = userGroupID;
  const [userGroupActionData, setUserGroupActionData] = useState([]);
  const [modifyUserGroupControl, setModifyUserGroupControl] = useState(false);
  const [originalSelectNodeGroup, setOriginalSelectNodeGroup] = useState([]);
  const [selectNodeGroupID, setSelectNodeGroupID] = useState("");
  const [deleteNode, setDeleteNode] = useState(false);
  const [deleteNodeGroupID, setDeleteNodeGroupID] = useState([]);
  const history = useHistory();

  const handleClose = () => {
    setOpenModifyNodeGroup(false);
    setActiveNodeGroupWindow(false);
    setSelectUserGroupID("");
    setSelectUserGroupName("");
    setUserGroupsNodeGroup([]);
    setDeleteNodeGroupID([]);
    setDeleteNode(false);
    setOriginalSelectNodeGroup([]);
  };

  const handleDeleteSelectNodeGroup = (e) => {
    setSelectNodeGroupID(e);
    setOriginalSelectNodeGroup(Array.from(userGroupsNodeGroup));
  };

  useEffect(() => {
    for (let i = 0; i < userGroupsNodeGroup.length; i++) {
      let originalID = userGroupsNodeGroup[i]._id;
      let selectID = selectNodeGroupID._id;

      if (originalID === selectID) {
        originalSelectNodeGroup.splice(i, 1);
        setDeleteNodeGroupID((deleteNodeGroupID) => [
          ...deleteNodeGroupID,
          selectID,
        ]);
        let result = Array.from(originalSelectNodeGroup);
        setDeleteNode(true);
        setUserGroupsNodeGroup(result);

        break;
      }
    }
  }, [originalSelectNodeGroup]);

  const submitDeleteNodeGroup = (e) => {
    for (let i = 0; i < deleteNodeGroupID.length; i++) {
      UserService.removeNodeGroupFromUserGroup(
        TokenService.getToken(),
        selectUserGroupID,
        deleteNodeGroupID[i]
      )
        .then((response) => {
          // console.log(response);
          swal(
            "Success!",
            "Remove Node Group From User Group Success!",
            "success"
          );
          handleClose();
        })
        .catch((error) => {
          swal("Error!", "Remove Node Group From User Group Fail!", "error");
          // console.log(error.response);
          handleError(error);
        });
    }
  };

  useEffect(() => {
    if (deleteNode) {
      setUserGroupActionData({
        columns: [
          { label: "", field: "order" },
          { label: "Node Name", field: "name" },
          { label: "Tags", field: "tags" },
          { label: "Delete", field: "delete", sort: false },
        ],
        rows: userGroupsNodeGroup.map((row, order) => {
          return {
            ...row,
            order: <div>{order + 1}</div>,

            delete: (
              <>
                <Button
                  as="a"
                  className="btn-node-edit "
                  data-toggle="modal"
                  data-target="#addOrg"
                  onClick={() => handleDeleteSelectNodeGroup(row)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#25AFA8"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </>
            ),
          };
        }),
      });
    }
  }, [userGroupsNodeGroup]);

  useEffect(() => {
    for (let i = 0; i < userGroupsNodeGroup.length; i++) {
      if (userGroupsNodeGroup[i].tags.length > 0) {
        let tempTags = "";
        for (let j = 0; j < userGroupsNodeGroup[i].tags.length; j++) {
          tempTags += userGroupsNodeGroup[i].tags[j] + " ";
        }
        userGroupsNodeGroup[i].tags = tempTags;
      }
      if (i === userGroupsNodeGroup.length - 1) {
        setUserGroupActionData(userGroupsNodeGroup[i].userGroupAction);
      }
    }

    setUserGroupActionData({
      columns: [
        { label: "", field: "order" },
        { label: "Node Name", field: "name" },
        { label: "Tags", field: "tags" },
        { label: "Delete", field: "delete", sort: false },
      ],
      rows: userGroupsNodeGroup.map((row, order) => {
        return {
          ...row,
          order: <div>{order + 1}</div>,

          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit nmc-btn-p"
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDeleteSelectNodeGroup(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#25AFA8"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });
  }, []);

  useEffect(() => {
    setModifyUserGroupControl(true);
    if (deleteNode) {
      setDeleteNode(false);
    }
  }, [setUserGroupActionData]);

  return (
    <Modal
      show={activeNodeGroupWindow}
      onHide={handleClose}
      className="fade"
      id="editOrg"
    >
      <div className="modal-content">
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">
            User Group's Node Groups
          </Modal.Title>
          <Button
            variant=""
            onClick={() => handleClose()}
            type="button"
            className="close"
          >
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="user-modal-body">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group">
              <div className="new-nodegroup">
                <div className="node-namebox mr-1">
                  <label className="normal-text mt-3 ml-1">User Group</label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={selectUserGroupName}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="mdbbox user-group-nodegroup">
              {modifyUserGroupControl && (
                <MDBDatatable
                  hover
                  data={userGroupActionData}
                  advancedData
                  entries={5}
                  entriesOptions={[5, 10, 15]}
                />
              )}
            </div>
          </form>
        </Modal.Body>
        <div className="nodegroup-submit">
          <Button
            as="a"
            to="/#"
            className="btn btn-primary new-nodegroup-submit"
            onClick={() => submitDeleteNodeGroup()}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyUserNodeGroup;
