import $ from "jquery";
import {
  MDBBtn,
  MDBDatatable,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";
import ErrorService from "../../service/error.service";
import NodeGroupService from "../../service/nodeGroup.service";
import TokenService from "../../service/token.service";
import EditNodeGroup from "./EditNodeGroup";

const NodeGroupList = ({
  rows,
  handleCompleteSubmitEdit,
  handleSelectRow,
  notify,
  setGroupListData,
}) => {
  const [open, setOpen] = useState(false);
  const [actionData, setActionData] = useState(null);
  const [nodeGroupList, setNodeGroupList] = useState([]);
  const [rowdelete, setRowDelete] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [modalState, setModalState] = useState({ from: "", message: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeEditModal, setActiveEditModal] = useState(false);
  const [nodeGroupDisplay, setNodeGroupDisplay] = useState([]);
  const [deleteNodeGroupWindow, setDeleteNodeGroupWindow] = useState(false);
  const [activeModalDeleteGroupWindow, setActiveModalDeleteGroupWindow] =
    useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [WarningText, setWarningText] = useState("");
  const [deleteNode, setDeleteNode] = useState(null);
  const [oKButton, setOKbutton] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectEditNodeGroup, setSelectEditNodeGroup] = useState([]);
  const [searchData, setSearchData] = useState("");
  const history = useHistory();

  useEffect(() => {
    let groupList = [];

    for (let i = 0; i < rows.length; i++) {
      let name = rows[i].name;
      let id = rows[i]._id;
      let tags = rows[i].tags.toString();
      groupList.push({ name: name, _id: id, tags: tags });
    }
    setNodeGroupDisplay(groupList);
    setNodeGroupList(groupList);
  }, []);

  useEffect(() => {
    let tempSelectGroupArray = [];
    for (let i = 0; i < selectedRows.length; i++) {
      tempSelectGroupArray.push(nodeGroupList[selectedRows[i]]);
    }

    handleSelectRow(tempSelectGroupArray);
  }, [selectedRows]);

  useEffect(() => {
    setOpen(true);
    setRowDelete(false);
  }, [actionData]);

  useEffect(() => {
    setActionData({
      columns: [
        { label: "", field: "order" },
        { label: "Node Name", field: "name", width: 150 },
        { label: "Tags", field: "tags", width: 150 },
        { label: "Edit", field: "edit", sort: false },
        { label: "Delete", field: "delete", sort: false },
      ],
      rows: nodeGroupList.map((row, order) => {
        return {
          order: <div>{order + 1}</div>,
          ...row,

          edit: (
            <>
              <Button
                as="a"
                className="btn-node-edit nodegrouplist-edit-btn "
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handelEditWidow(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="gray"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </Button>
            </>
          ),
          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit nodegrouplist-edit-btn "
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDeleteNodeGroup(row)}
              >
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 19 19"
                  fill="gray"
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });
  }, [nodeGroupList]);

  const handleSubmimtSearch = () => {
    if ($("#nodeGroupSearch").val() === "") {
      setNodeGroupList(nodeGroupDisplay);
    } else {
      //keep the search data
      setSearchData($("#nodeGroupSearch").val());

      // for more feature in the future, eg. search by [tag1,tag2]
      let result = $("#nodeGroupSearch")
        .val()
        .match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);

      for (let i = 0; i < result.length; i++) {
        let filteredStrings = nodeGroupList.filter((node) =>
          node.name.toLowerCase().includes(result[i].toLowerCase())
        );

        if (filteredStrings.length > 0) {
          setNodeGroupList(filteredStrings);
        }

        let filteredStrings2 = nodeGroupList.filter((node) =>
          node.tags.toLowerCase().includes(result[i].toLowerCase())
        );

        if (filteredStrings2.length > 0) {
          setNodeGroupList(filteredStrings2);
        }
      }
    }
  };

  const handleError = (error) => {
    ErrorService.handleResponseError(error)
      .then((response) => {
        console.log(response);
        if (response === "Refresh Token Expired") {
          swal(
            "Your session has expired!",
            "Please login again.",
            "warning"
          ).then(() => {
            history.push("/");
          });
        } else if (response === "Refresh token success") {
          window.location.reload();
        } else if (response === "Not Login") {
          swal(
            "It looks like you aren't logged in.",
            "Please log in to see this page.",
            "warning"
          ).then(() => {
            localStorage.clear();
            history.push("/");
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (deleteNode !== null) {
      let tempRow = Array.from(rows);
      for (let i = 0; i < rows.length; i++) {
        let tempid;
        //console.log(rows[i]);
        if (rows[i]._id === deleteNode._id) {
          tempRow.splice(i, 1);
        }
        //Reload the tab label number
        setGroupListData(tempRow);
      }
    }
  }, [nodeGroupDisplay]);

  const handleSubmitDeleteNodeGroup = (e) => {
    try {
      NodeGroupService.deleteNodeGroup(TokenService.getToken(), e._id)
        .then((response) => {
          console.log(response);

          let index;
          for (let i = 0; i < nodeGroupList.length; i++) {
            if (nodeGroupList[i]._id === e._id) {
              index = i;
              nodeGroupList.splice(index, 1);
              let tempNodeGroupList = Array.from(nodeGroupList);
              let tempNodeGroupDisplay = Array.from(nodeGroupDisplay);
              for (let i = 0; i < nodeGroupDisplay.length; i++) {
                if (nodeGroupDisplay[i].id === e._id) {
                  tempNodeGroupDisplay.splice(i, 1);
                }
                setNodeGroupDisplay(tempNodeGroupDisplay);
              }

              //reload the gorup list
              setNodeGroupList(tempNodeGroupList);

              setDeleteNodeGroupWindow(false);
              setActiveModalDeleteGroupWindow(false);
              handleDeleteSuccess();

              break;
            }
          }
        })
        .catch((error) => {
          //console.log(error.response);
          handleError(error);
          if (error.response.status === 409) {
            setOKbutton(true);
            setDeleteText("");
            setWarningText(
              "The node group is not empty! \n Please delete all nodes in this node group before you delete the node group."
            );
          }
        });
    } catch (e) {
      //console.log(e);
    }
  };

  const handleDeleteSuccess = () => {
    swal("Success!", "Delete Success!", "success");
  };

  const handleDeleteNodeGroup = (e) => {
    setDeleteNode(e);
    let text = "Are you sure you want to delete " + e.name + "?";

    setDeleteText(text);
    swal({
      title: "Delete Node Group",
      text: text,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleSubmitDeleteNodeGroup(e);
      }
    });
  };

  const handelEditWidow = (e) => {
    setSelectEditNodeGroup(e);
    setActiveEditModal(true);
    setOpenEdit(true);
  };

  return (
    open && (
      <>
        <div className="node-search-block search-grouplist-input mb-3">
          <input
            placeholder="Search Group Name"
            id="nodeGroupSearch"
            type="text"
            className="form-control node-search-input "
          />
          <Button
            to="/#"
            className="btn-node-search"
            onClick={() => handleSubmimtSearch()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </Button>
        </div>

        <div className="nodelist-group">
          <div
            className="node-list-table node-lsit-table-position node-grouplist"
            id="nodelist"
          >
            <MDBDatatable
              selectable
              multi
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              hover
              data={actionData}
              advancedData
              entries={8}
              entriesOptions={[8, 16, 24]}
              // clickableRows={(row) => handleClickRow(row)}
            />
          </div>

          <MDBModal
            show={basicModal}
            getOpenState={(e: any) => setBasicModal(e)}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>User Detail</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => setBasicModal(false)}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <h6 className="mb-4">
                    User Email: <strong>{modalState.from}</strong>
                  </h6>
                  <p>{modalState.message}</p>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </div>

        {openEdit && (
          <EditNodeGroup
            nodeGroupEditActiveModal={[activeEditModal, setActiveEditModal]}
            open={[openEdit, setOpenEdit]}
            selectNodeGroup={selectEditNodeGroup}
            handleCompleteSubmitEdit={handleCompleteSubmitEdit}
          />
        )}
      </>
    )
  );
};

export default NodeGroupList;
