import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Permissions } from "../../utils/constants";
import { handleError } from "../../utils/utils";
import TokenService from "../service/token.service";
import UserService from "../service/user.service";
import xssFilterService from "../service/xssfilter.service";
import UserTable from "./UserTable";
import UserTableAdmin from "./UserTableAdmin";

const EditUserGroupModal = ({
  title,
  userOrg,
  selectedUserGroup,
  opnModal,
  allUserList,
  selectGroupMembers,
  allPermissions,
  handleReloadPage,
  isInAdminGroup,
}) => {
  const [largeModal, setLargeModal] = opnModal;
  const [groupName, setGroupName] = useState("");
  const [selectUserGroup, setSelectUserGroup] = selectedUserGroup;
  const [warningShown, setWarningShown] = useState(false);
  const [displayUserList, setDisplayUserList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editUserSelectRows, setEditUserSelectRows] = useState([]);
  const [groupPermission, setGroupPermission] = useState([""]);
  const [remainPermissions, setRemainPermissions] = useState([""]);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [tempGroupPermissions, setTempGroupPermissions] = useState([""]);
  const [canEditPermissions, setCanEditPermissions] = useState(false);
  const [warningCount, setWarningCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setGroupName(selectUserGroup.name);
    setGroupPermission(selectUserGroup.permissions);
    //get default selected group permissions for display
    const defaultSelectedItems = new Set(selectedItems);
    for (let i = 0; i < selectUserGroup.permissions.length; i++) {
      defaultSelectedItems.add(selectUserGroup.permissions[i]);
    }
    setSelectedItems(defaultSelectedItems);
    setTempGroupPermissions(Array.from(defaultSelectedItems));

    //check if user is manager of the group
    for (let i = 0; i < selectGroupMembers.managers.length; i++) {
      if (
        selectGroupMembers.managers[i]._id ===
        JSON.parse(localStorage.getItem("userID"))
      ) {
        setCanEditPermissions(true);
      }
    }
  }, []);

  //get remaining permissions for display
  useEffect(() => {
    const filteredGroupPermission = allPermissions.filter(
      (permission) => !groupPermission.includes(permission)
    );

    setRemainPermissions(filteredGroupPermission);
  }, [groupPermission]);

  useEffect(() => {
    setDisplayUserList(selectGroupMembers.members);
  }, [selectGroupMembers]);

  const handleCloseWindow = (e) => {
    setLargeModal(false);
    setSelectUserGroup(null);
    setEditUserSelectRows([]);
  };

  const handleDeleteUser = () => {
    let deleteRows = [];
    if (selectUserGroup.name === "admin") {
      deleteRows = selectedRows;
    } else {
      deleteRows = editUserSelectRows;
    }
    //
    const deletePromises = deleteRows.map((row) => {
      return UserService.removeUserFromGroup(
        TokenService.getToken(),
        selectUserGroup._id,
        selectUserGroup.name === "admin" ? displayUserList[row]._id : row
      );
    });

    return Promise.all(deletePromises);
  };

  const handleDeleteAndSave = () => {
    handleDeleteUser();
    swal("Success!", "You have successfully delete the member", "success").then(
      () => {
        handleCloseWindow();
        handleReloadPage();
      }
    );
  };

  //handle the permission setting
  const toggleSelectedItem = async (item) => {
    const newSelectedItems = new Set(selectedItems);

    if (selectedItems.has(item)) {
      if (
        item === Permissions.MANAGE_NODES_NORMAL_ACTION &&
        selectedItems.has(Permissions.MANAGE_NODES_CRITICAL_ACTION)
      ) {
        // show warning if user is trying to remove 'Manage Nodes Normal Action' when 'Manage Nodes Critical Action' is still selected
        Swal.fire({
          icon: "warning",
          text: "With 'Manage Nodes Critical Action' permission, you can't remove 'Manage Nodes Normal Action' individually.",
        });
      } else {
        // Show the warning only if it's the first time the item is being removed
        if (!warningShown || warningCount === 0) {
          const result = await Swal.fire({
            title: "Warning",
            text: "Removing this permission will also remove it from all existing members.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Remove",
            cancelButtonText: "Cancel",
          });

          // If the user confirms the removal, proceed
          if (result.isConfirmed) {
            newSelectedItems.delete(item);
            setSelectedItems(newSelectedItems);
            setTempGroupPermissions(Array.from(newSelectedItems));

            // Update the warningShown state
            setWarningShown(true);
          }
          setWarningCount(warningCount + 1);
        } else {
          newSelectedItems.delete(item);
          setSelectedItems(newSelectedItems);
          setTempGroupPermissions(Array.from(newSelectedItems));
        }
      }
    } else {
      // If the user is trying to add 'Manage Nodes Critical Action' when 'Manage Nodes Normal Action' is not selected, add 'Manage Nodes Normal Action' as well
      if (
        item === Permissions.MANAGE_NODES_CRITICAL_ACTION &&
        !selectedItems.has(Permissions.MANAGE_NODES_NORMAL_ACTION)
      ) {
        newSelectedItems.add(Permissions.MANAGE_NODES_NORMAL_ACTION);
      }
      newSelectedItems.add(item);
      setSelectedItems(newSelectedItems);
      setTempGroupPermissions(Array.from(newSelectedItems));
      setWarningShown(true);
    }
  };

  const handleSaveEditGroup = async () => {
    const promises = [];
    //check if group name and permissions are changed
    if (
      selectUserGroup.name !== groupName ||
      JSON.stringify(selectUserGroup.permissions.sort()) !==
        JSON.stringify(Array.from(selectedItems).sort())
    ) {
      await UserService.editUserGroup(
        TokenService.getToken(),
        selectUserGroup._id,
        groupName,
        Array.from(selectedItems)
      );
    }

    for (let i = 0; i < displayUserList.length; i++) {
      if (!editUserSelectRows.includes(displayUserList[i]._id)) {
        if (displayUserList[i]["permissionsChanged"]) {
          // console.log(displayUserList[i]);
          promises.push(
            UserService.addGroupMemberPermissions(
              TokenService.getToken(),
              selectUserGroup._id,
              displayUserList[i]._id,
              displayUserList[i]["permissions"]
            )
          );
        }
        if (displayUserList[i]["managerChanged"]) {
          //console.log(displayUserList[i]);
          promises.push(
            UserService.editUserGroupStatus(
              TokenService.getToken(),
              selectUserGroup._id,
              displayUserList[i]._id,
              displayUserList[i]["isManager"]
            )
          );
        }
      }
    }
    promises.push(handleDeleteUser());

    // await Promise.all(promises);

    try {
      swal(
        "Success!",
        "You have successfully edited the group",
        "success"
      ).then(() => {
        handleCloseWindow();
        handleReloadPage();
      });
    } catch (error) {
      //console.error("Error while saving group edits", error);
      handleError(error, history);
    }
  };

  const handleSetGroupName = (e) => {
    let newGroupName = e.target.value;
    if (newGroupName === "admin") {
      setGroupName(xssFilterService.removeHtmlTags("admin"));
    } else {
      setGroupName(xssFilterService.removeHtmlTags(newGroupName));
    }
  };

  return (
    <Modal
      className="fade bd-example-modal-lg "
      show={largeModal}
      onHide={handleCloseWindow}
      size="lg"
    >
      <Modal.Body className="card-header-tab">
        <div className="card">
          <Tab.Container defaultActiveKey="groupname">
            <div className="card-tab-shadow">
              <div className="card-header d-sm-flex d-block pb-0 border-0 card-header-tabtitle">
                <Button
                  variant=""
                  className="close close-intab close-lg-display"
                  onClick={() => handleCloseWindow()}
                >
                  <span>&times;</span>
                </Button>
                <div className="">
                  <h4 className="header-title fs-20 mt-2 ">{title}</h4>
                </div>
                <div className="card-action  card-tabs  ">
                  <Nav as="ul" className="nav nav-tabs" role="tablist">
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-item-tab"
                        data-toggle="tab"
                        eventKey="groupname"
                      >
                        Group Name
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="nav-item">
                      <Nav.Link
                        id="add-users-btn"
                        className="nav-item-tab"
                        eventKey="adduser"
                      >
                        Modify members
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Button
                  variant=""
                  className="close close-intab ml-5 close-btn-position"
                  onClick={() => handleCloseWindow()}
                >
                  <span>&times;</span>
                </Button>
              </div>
            </div>

            <div className="">
              <Tab.Content className="tab-content">
                <Tab.Pane role="tabpanel" eventKey="groupname">
                  <div className="featured-menus owl-carousel">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="items mt-4">
                        <div className="form-group  px-5 ">
                          <label className="normal-text">
                            Organization Name*
                          </label>
                          <input
                            type="text"
                            className="form-control mb-4"
                            placeholder={userOrg}
                            disabled
                          />
                          <label className="normal-text">Group Name*</label>
                          <input
                            onChange={(e) => handleSetGroupName(e)}
                            type="text"
                            className="form-control mb-1"
                            value={groupName}
                            disabled={groupName === "admin"}
                            required
                          />
                        </div>
                      </div>
                      <div className="permission-token-admin">
                        <label className="normal-text">Permissions*</label>
                        <ul className="token-list">
                          {groupPermission.length > 0 &&
                            groupPermission.map((item, index) => {
                              if (
                                item ===
                                Permissions.MANAGE_NODES_CRITICAL_ACTION
                              ) {
                                return (
                                  <li
                                    key={index}
                                    className={`${
                                      groupName === "admin"
                                        ? "disabled"
                                        : "cri-button"
                                    } ${isInAdminGroup ? "" : "disabled"}`}
                                  >
                                    <button
                                      onClick={() =>
                                        toggleSelectedItem(
                                          Permissions.MANAGE_NODES_CRITICAL_ACTION
                                        )
                                      }
                                      className={`${
                                        selectedItems.has(
                                          Permissions.MANAGE_NODES_CRITICAL_ACTION
                                        )
                                          ? "selected"
                                          : ""
                                      }
                                      ${isInAdminGroup ? "" : "disabled"}
                                      `}
                                    >
                                      manage nodes critical action
                                    </button>

                                    <button
                                      onClick={() =>
                                        toggleSelectedItem(
                                          Permissions.MANAGE_NODES_NORMAL_ACTION
                                        )
                                      }
                                      className={`${
                                        selectedItems.has(
                                          Permissions.MANAGE_NODES_NORMAL_ACTION
                                        )
                                          ? "selected"
                                          : ""
                                      }
                                      ${isInAdminGroup ? "" : "disabled"}
                                      `}
                                    >
                                      manage nodes normal action
                                    </button>
                                  </li>
                                );
                              } else if (
                                item === Permissions.MANAGE_NODES_NORMAL_ACTION
                              ) {
                              } else {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => toggleSelectedItem(item)}
                                    className={`${
                                      groupName === "admin" ? " disabled" : ""
                                    }${
                                      selectedItems.has(item) ? " selected" : ""
                                    } ${isInAdminGroup ? "" : "disabled"}`}
                                  >
                                    {item}
                                  </li>
                                );
                              }
                            })}

                          {groupName !== "admin" &&
                            remainPermissions.map((item, index) => {
                              if (
                                item ===
                                Permissions.MANAGE_NODES_CRITICAL_ACTION
                              ) {
                                return (
                                  <li
                                    key={index}
                                    className={`${
                                      selectedItems.has(item)
                                        ? " selected cri-button"
                                        : "cri-button"
                                    }
                                ${isInAdminGroup ? "" : "disabled"}
                                `}
                                  >
                                    <button
                                      onClick={() =>
                                        toggleSelectedItem(
                                          Permissions.MANAGE_NODES_CRITICAL_ACTION
                                        )
                                      }
                                      className={`${
                                        selectedItems.has(
                                          Permissions.MANAGE_NODES_CRITICAL_ACTION
                                        )
                                          ? "selected"
                                          : ""
                                      }
                                      ${isInAdminGroup ? "" : "disabled"}
                                      `}
                                    >
                                      manage nodes critical action
                                    </button>

                                    <button
                                      onClick={() =>
                                        toggleSelectedItem(
                                          Permissions.MANAGE_NODES_NORMAL_ACTION
                                        )
                                      }
                                      className={`${
                                        selectedItems.has(
                                          Permissions.MANAGE_NODES_NORMAL_ACTION
                                        )
                                          ? "selected"
                                          : ""
                                      }
                                      ${isInAdminGroup ? "" : "disabled"}
                                      `}
                                    >
                                      manage nodes normal action
                                    </button>
                                  </li>
                                );
                              } else if (
                                item === Permissions.MANAGE_NODES_NORMAL_ACTION
                              ) {
                              } else {
                                return (
                                  <li
                                    key={index}
                                    className={`${
                                      selectedItems.has(item) ? " selected" : ""
                                    }
                                ${isInAdminGroup ? "" : "disabled"}
                                `}
                                    onClick={() =>
                                      isInAdminGroup
                                        ? toggleSelectedItem(item)
                                        : console.log("Can not select")
                                    }
                                  >
                                    {item}
                                  </li>
                                );
                              }
                            })}
                        </ul>
                      </div>
                    </form>
                  </div>
                </Tab.Pane>

                <Tab.Pane role="tabpanel" eventKey="adduser">
                  {selectUserGroup.name === "admin" && (
                    <div className="featured-menus owl-carousel ">
                      <UserTableAdmin
                        userList={displayUserList ? displayUserList : []}
                        selectRow={[selectedRows, setSelectedRows]}
                      />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          className="btn btn-primary mr-4"
                          style={{
                            padding: "1rem 3rem",
                          }}
                          onClick={() => handleDeleteAndSave()}
                        >
                          Delete & Save
                        </Button>
                      </div>
                    </div>
                  )}
                  {selectUserGroup.name !== "admin" && (
                    <div className="featured-menus owl-carousel ">
                      <UserTable
                        userList={displayUserList ? displayUserList : []}
                        selectRow={[editUserSelectRows, setEditUserSelectRows]}
                        groupPermission={tempGroupPermissions}
                        canEditPermissions={canEditPermissions}
                        isInAdminGroup={isInAdminGroup}
                      />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          className="btn btn-primary mr-4"
                          style={{
                            padding: "1rem 3rem",
                          }}
                          onClick={() => handleSaveEditGroup()}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserGroupModal;
