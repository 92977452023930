import axios from "axios";
import { getHeader, lsDecrypt } from "../../utils/utils";
require("dotenv").config();

const NODECONTROL_SETUP_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_control_setup/nodes/";
const NODECONTROL_INFO_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_control_info/nodes/";

const NODE_MANAGEMENT_BASIC_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_management/";

const NODE_MANAGEMENT_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_management/nodes/";

const NODES_CONTROL_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_control/nodes/";

const GETPENDINGNODE_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_management/pending/";

const MANAGEPENDINGNODES_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_management/join_status";

const GETNODELIST_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_management/nodes";

const NODECONTROL_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/node_event_reaction/nodes/";

const GETABANDONODELIST =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_management/abandoned_nodes";

const oneShot = {
  csw: "off",
};

const p80OneShot = {
  p80: "off",
};

const dbgOneShot = {
  dbg: "off",
};

const fwupdOneShot = {
  fwupd: "off",
};

const solOneShot = {
  sol: "off",
};

const pwrOneShot = {
  pwr: "off",
};

const startRpt = {
  sen: "off",
};

// const startEvtRpt = {
//   evt: "off",
// };

const startACPIEvtRpt = {
  evt_acpi: "off",
};

const startSenEvtRpt = {
  evt_sen: "off",
};

const skcOneShot = {
  skc: "off",
};

const sysrptOneShot = {
  sysrpt: "off",
};

class nodeService {
  getSOLStatus() {
    return solOneShot.sol;
  }

  getACPIEvtStatus() {
    return startACPIEvtRpt.evt_acpi;
  }

  getSenEvtStatus() {
    return startSenEvtRpt.evt_sen;
  }

  getDBGOneShot() {
    return dbgOneShot.dbg;
  }

  getP80Oneshot() {
    return p80OneShot.p80;
  }

  getRegularReportStatus(input) {
    return startRpt[input];
  }

  setSkcOneShot(input, value) {
    switch (input) {
      case "skc":
        skcOneShot.skc = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }
    // console.log(skcOneShot);
  }

  setP80OneShot(input, value) {
    switch (input) {
      case "p80":
        p80OneShot.p80 = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }
    // console.log(p80OneShot);
  }

  setDbgOneShot(input, value) {
    switch (input) {
      case "dbg":
        dbgOneShot.dbg = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }
    // console.log(dbgOneShot);
  }

  setFWUpdOneShot(input, value) {
    switch (input) {
      case "fwupd":
        fwupdOneShot.fwupd = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }

    //console.log(fwupdOneShot);
  }

  setSOLOneShot(input, value) {
    switch (input) {
      case "sol":
        solOneShot.sol = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }

    // console.log(solOneShot);
  }

  setPWROneShot(input, value) {
    switch (input) {
      case "pwr":
        pwrOneShot.pwr = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }

    // console.log(pwrOneShot);
  }

  // one shot for system report
  setSysrptShot(input, value) {
    switch (input) {
      case "sysrpt":
        sysrptOneShot.sysrpt = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }

    // console.log(sysrptOneShot);
  }

  setOneShot(input, value) {
    switch (input) {
      case "csw":
        oneShot.csw = value;
        break;
      default:
        console.log(`Sorry, ${input} is not in the one shot list.`);
    }

    // console.log(oneShot);
  }

  setFirmwareUpdateParameter(token, nodeid, fwid) {
    return axios({
      method: "put",
      url: NODECONTROL_SETUP_URL + nodeid + "/firmware_update",
      headers: getHeader(token),
      data: fwid,
    });
  }

  setRegularReport(input, value) {
    switch (input) {
      case "sen":
        startRpt.sen = value;
        break;

      default:
        console.log(`Sorry, ${input} is not in the regular report list.`);
    }

    // console.log(startRpt);
  }

  setACPIRegularReport(input, value) {
    switch (input) {
      case "evt_acpi":
        startACPIEvtRpt.evt_acpi = value;
        break;

      default:
        console.log(`Sorry, ${input} is not in the regular report list.`);
    }

    // console.log(startACPIEvtRpt);
  }

  setSenRegularReport(input, value) {
    switch (input) {
      case "evt_sen":
        startSenEvtRpt.evt_sen = value;
        break;

      default:
        console.log(`Sorry, ${input} is not in the regular report list.`);
    }

    // console.log(startSenEvtRpt);
  }

  setRegularReportParameter(token, id, interval) {
    return axios({
      method: "put",
      url: NODECONTROL_SETUP_URL + id + "/regular_report",
      headers: getHeader(token),
      data: {
        sen: {
          interval: interval,
        },
      },
    });
  }

  setCSWParameter(token, id, parameters) {
    return axios({
      method: "put",
      url: NODECONTROL_SETUP_URL + id + "/console_watch",
      headers: getHeader(token),
      data: parameters,
    });
  }

  setPort80(token, id, parameters) {
    return axios({
      method: "put",
      url: NODECONTROL_SETUP_URL + id + "/port_80",
      headers: getHeader(token),
      data: parameters,
    });
  }

  getNode(token, id) {
    return axios({
      method: "get",
      url: NODE_MANAGEMENT_URL + id,
      headers: getHeader(token),
      data: {},
    });
  }

  getPendingNode(token) {
    return axios.get(GETPENDINGNODE_URL, {
      headers: getHeader(token),
    });
  }

  getNodeList(token) {
    return axios.get(GETNODELIST_URL, {
      headers: getHeader(token),
    });
  }

  getNodeArray(token, nodeArray) {
    let url = "";

    for (let i = 0; i < nodeArray.length; i++) {
      if (i === 0) {
        url =
          NODE_MANAGEMENT_BASIC_URL +
          "nodes_array?nodes_id[]=" +
          nodeArray[i]._id;
      } else {
        url = url + "&nodes_id[]=" + nodeArray[i]._id;
      }
    }

    return axios.get(url, {
      headers: getHeader(token),
    });
  }

  getNodeByUserGroup(token) {
    // let nodeGroups = JSON.parse(localStorage.getItem("nodeGroups"));
    let nodeGroups = JSON.parse(lsDecrypt("nodeGroups"));
    let url = "";

    for (let i = 0; i < nodeGroups?.nodeGroup?.length; i++) {
      if (i === 0) {
        url =
          NODE_MANAGEMENT_BASIC_URL +
          "node_group_array?groups_id[]=" +
          nodeGroups.nodeGroup[i]._id;
      } else {
        url = url + "&groups_id[]=" + nodeGroups.nodeGroup[i]._id;
      }
    }

    return axios.get(url, {
      headers: getHeader(token),
    });
  }

  getANode(token, id) {
    return axios({
      method: "get",
      url: NODE_MANAGEMENT_URL + id,
      headers: getHeader(token),
      data: {},
    });
  }

  getNodeSensorReading(token, id) {
    return axios({
      method: "get",
      url: NODE_MANAGEMENT_URL + id + "/sensor",
      headers: getHeader(token),
      data: {},
    });
  }

  getFWUpdateInfo(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/firmware_update",
      headers: getHeader(token),
      data: {},
    });
  }

  getTargetInfo(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/target",
      headers: getHeader(token),
      data: {},
    });
  }

  getEventLog(token, id, eventType) {
    return axios({
      method: "get",
      url: NODES_CONTROL_URL + id + "/log/event/" + eventType,
      headers: getHeader(token),
      data: {},
    });
  }

  getConsoleWatchLogList(token, id) {
    return axios({
      method: "get",
      url: NODES_CONTROL_URL + id + "/log/console_watch",
      headers: getHeader(token),
      data: {},
    });
  }

  getConsoleWatchLog(token, nodeid, logid) {
    return axios({
      method: "get",
      url: NODES_CONTROL_URL + nodeid + "/log/console_watch/" + logid,
      headers: getHeader(token),
      data: {},
    });
  }

  getConsoleWatchInfo(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/console_watch",
      headers: getHeader(token),
      data: {},
    });
  }

  getPowerCycleInfo(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/power_cycle",
      headers: getHeader(token),
      data: {},
    });
  }

  getSignKeyChangeInfo(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/skc",
      headers: getHeader(token),
      data: {},
    });
  }

  getP80Info(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/port_80",
      headers: getHeader(token),
      data: {},
    });
  }

  getDBG(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_INFO_URL + id + "/debug",
      headers: getHeader(token),
      data: {},
    });
  }

  postOneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: oneShot,
    });
  }
  postP80OneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: p80OneShot,
    });
  }

  postDbgOneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: dbgOneShot,
    });
  }

  postPWROneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: pwrOneShot,
    });
  }

  postSOLOneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: solOneShot,
    });
  }

  postFWUpdOneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: fwupdOneShot,
    });
  }

  postSkcOneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: skcOneShot,
    });
  }

  postSysrptOneShotTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/one_shot_tasks",
      headers: getHeader(token),
      data: sysrptOneShot,
    });
  }

  postRegularTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/regular_report_tasks",
      headers: getHeader(token),
      data: startRpt,
    });
  }

  getSensorDataSearch(token, id, countNum, begin, end) {
    let url = "";
    if (countNum !== "") {
      const params = new URLSearchParams({
        count: countNum,
      }).toString();
      url = NODE_MANAGEMENT_URL + id + "/sensor?" + params;
    } else {
      url = NODE_MANAGEMENT_URL + id + "/sensor?";
    }

    if (begin !== "") {
      url = url + "&begin=" + begin;
    }
    if (end !== "") {
      url = url + "&end=" + end;
    }

    return axios.get(url, {
      headers: getHeader(token),
    });
  }

  // postEvtRegularTask(token, id) {
  //   return axios({
  //     method: "post",
  //     url: NODES_CONTROL_URL + id + "/regular_report_tasks",
  //     headers: getHeader(token),
  //     data: startEvtRpt,
  //   });
  // }

  postACPIEvtRegularTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/regular_report_tasks",
      headers: getHeader(token),
      data: startACPIEvtRpt,
    });
  }

  postSenEvtRegularTask(token, id) {
    return axios({
      method: "post",
      url: NODES_CONTROL_URL + id + "/regular_report_tasks",
      headers: getHeader(token),
      data: startSenEvtRpt,
    });
  }

  postOneShotParameter(token, id, module, parameters) {
    return axios({
      method: "put",
      url: NODECONTROL_SETUP_URL + id + "/one_shot_action",
      headers: getHeader(token),
      data: {
        module: module,
        parameters: parameters,
      },
    });
  }

  deleteConsoleWatchLog(token, nodeid, logid) {
    return axios({
      method: "delete",
      url: NODES_CONTROL_URL + nodeid + "/log/console_watch/" + logid,
      headers: getHeader(token),
      data: {},
    });
  }
  deleteEventLog(token, id, logid) {
    return axios({
      method: "delete",
      url: NODES_CONTROL_URL + id + "/log/event/" + logid,
      headers: getHeader(token),
      data: {},
    });
  }

  editNode(token, id, org, suborg, loc) {
    return axios({
      method: "patch",
      url: NODE_MANAGEMENT_URL + id,
      headers: getHeader(token),
      data: {
        desc: {
          org: org,
          sub_org: suborg,
          location: loc,
        },
      },
    });
  }

  editPendingNodeStatus(token, id, joinStatus) {
    return axios({
      method: "patch",
      url: MANAGEPENDINGNODES_URL,
      headers: getHeader(token),
      data: {
        nodes: [
          {
            _id: id,
            joinStatus: joinStatus,
          },
        ],
      },
    });
  }

  addNodeEvtReaction(token, id, type, infoData, reactionName) {
    return axios({
      method: "post",
      url: NODECONTROL_URL + id,
      headers: getHeader(token),
      data: {
        event_type: type,
        event_info: {
          data: infoData,
        },
        reaction: [
          {
            name: reactionName,
          },
        ],
      },
    });
  }

  getNodeEvtReaction(token, id) {
    return axios({
      method: "get",
      url: NODECONTROL_URL + id,
      headers: getHeader(token),
      data: {},
    });
  }

  deleteNodeEventReaction(token, nodeid, reactionid) {
    return axios({
      method: "delete",
      url: NODECONTROL_URL + nodeid + "/reaction/" + reactionid,
      headers: getHeader(token),
      data: {},
    });
  }

  getAbandonNodeList(token) {
    return axios({
      method: "get",
      url: GETABANDONODELIST,
      headers: getHeader(token),
      data: {},
    });
  }
}

export default new nodeService();
