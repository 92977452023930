import format from "date-fns/format";
import { filterData, SearchType } from "filter-data";
import keycode from "keycode";
import NodeService from "../../service/node.service";
import TokenService from "../../service/token.service";
export const NodeHelper = () => {
  return <div>NodeHelper</div>;
};

//server time to local time (GMT+0800)
export function timeToLocalTime(time) {
  let dateTime = new Date(time).toString().slice(4, 25);

  let resultTime =
    dateTime.slice(4, 6) +
    " " +
    dateTime.slice(0, 3) +
    " " +
    dateTime.slice(7, 11) +
    ", " +
    dateTime.slice(12, 20);

  return resultTime;
}

export async function getNodeList() {
  let result = [];
  await NodeService.getNodeList(TokenService.getToken())
    .then((response) => {
      console.log(response);
      result = JSON.parse(JSON.stringify(response.data.nodes));
    })
    .catch((error) => {
      console.log(error.response);
      result = error.response;
    });
  return result;
}
export async function handleNodeData(nodelist) {
  let fwver = "";
  let bios = "";
  let osver = "";
  let senoldtime = "";
  let senolddata = "";
  let senstatus = "";
  let evtStatus = "";
  let evtAcpiStatus = "";
  let evtSenStatus = "";

  let newNodeList = [];

  for (let i = 0; i < nodelist.length; i++) {
    if (nodelist[i].sw_info !== undefined) {
      fwver = nodelist[i].sw_info.fwver;
      osver = nodelist[i].sw_info.osver;
      bios = nodelist[i].sw_info.bios;
    }

    //set sensor old time
    if (nodelist[i].req_report_control !== undefined) {
      if (nodelist[i].req_report_control.sen === "on") {
        if (
          nodelist[i].req_setup_report_info !== undefined ||
          nodelist[i].req_setup_report_info !== null
        ) {
          senoldtime = nodelist[i].req_setup_report_info.sen.interval;
          senolddata = nodelist[i].sens_info;
          senstatus = "on";
        }
      } else {
        // console.log("sensor data is-------");
        // console.log(nodelist[i].req_report_control.sen);
        senstatus = "off";
      }

      //old event
      if (nodelist[i].req_report_control.evt) {
        if (nodelist[i].req_report_control.evt === "on") {
          evtStatus = "on";
        } else {
          evtStatus = "off";
        }
      }

      //new event
      if (nodelist[i].req_report_control.evt_sen) {
        evtSenStatus = nodelist[i].req_report_control.evt_sen;
      }

      if (nodelist[i].req_report_control.evt_acpi) {
        evtAcpiStatus = nodelist[i].req_report_control.evt_acpi;
      }
    }

    let obj = {
      id: nodelist[i]._id,
      nodename: nodelist[i].name,
      org: nodelist[i].desc.org,
      suborg: nodelist[i].desc.sub_org,
      location: nodelist[i].desc.location,
      fwver: fwver,
      bios: bios,
      osver: osver,
      ability: nodelist[i].ability,
      onlineStatus: nodelist[i].health.reachable.toString(),
      // host: nodelist[i].health.reachable ? nodelist[i].health.host : "off",
      host: nodelist[i].acpi_state === "s0" ? "on" : "off",
      eventNum: nodelist[i].eventCount,
      evtInfo: nodelist[i].evt_info,
      provision: nodelist[i].provision_info,
      senoldtime: senoldtime,
      senolddata: senolddata,
      senstatus: senstatus,
      evtStatus: evtStatus,
      evtAcpiStatus: evtAcpiStatus,
      evtSenStatus: evtSenStatus,
    };

    newNodeList.push(obj);
  }

  return newNodeList;
}

export async function search(data, condition) {
  const result = filterData(data, condition);
  return result;
}

export function searchCondition(searchKey, searchValue, typeString) {
  let inputType = "";
  if (typeString === "eq") {
    inputType = SearchType.EQ;
  } else if (typeString === "gt") {
    inputType = SearchType.GT;
  } else if (typeString === "lk") {
    inputType = SearchType.LK;
  }

  let obj = {
    key: searchKey,
    value: searchValue,
    type: inputType,
  };
  return obj;
}

export function getSearchLabelList(dataList) {
  let orgSet = new Set();
  let subOrgSet = new Set();
  let locationSet = new Set();
  let osSet = new Set();
  let fwVerSet = new Set();
  let biosSet = new Set();
  for (let i = 0; i < dataList.length; i++) {
    orgSet.add(dataList[i].org);
    subOrgSet.add(dataList[i].suborg);
    locationSet.add(dataList[i].location);
    osSet.add(dataList[i].osver);
    fwVerSet.add(dataList[i].fwver);
    biosSet.add(dataList[i].bios);
  }

  let resultList = [
    Array.from(orgSet),
    Array.from(subOrgSet),
    Array.from(locationSet),
    Array.from(osSet),
    Array.from(fwVerSet),
    Array.from(biosSet),
  ];

  return resultList;
}

export function keyCodeHelper(button) {
  return keycode(button);
}

//handle virtual keyboard key mapping
export function switchVirtualKeyboardKey(key) {
  // console.log("switch Virtual keyboard");
  // console.log(key);
  let Resultkey = "";
  switch (key) {
    case "delete": //Delete
      Resultkey = "\x1B[3~";
      break;

    case "end": //End
      Resultkey = "\x1B[4~";
      break;

    case "pageup": //Pageup
      Resultkey = "\x1B[5~";
      break;
    case "pagedown": //Pagedown
      Resultkey = "\x1B[6~";
      break;
    case "home": //Home
      Resultkey = "\x1B[H";
      break;
    case "insert": //Insert
      Resultkey = "\x1B[2~";
      break;
    case "enter": //Enter
      Resultkey = "\r";
      break;
    case "backspace": //Backspace
      Resultkey = "\b";
      break;
    case "space": //space
      Resultkey = " ";
      break;
    case "tab": //Tab
      Resultkey = "\t";
      break;

    case "escape": // esc
      Resultkey = "\x1B";
      break;

    case "arrowup": // Arrow up
      Resultkey = "\x1B[A";
      break;
    case "arrowdown": // Arrow down
      Resultkey = "\x1B[B";
      break;
    case "arrowright": // Arrow down
      Resultkey = "\x1B[C";
      break;
    case "arrowleft": // Arrow down
      Resultkey = "\x1B[D";
      break;
    case "f1": // F1
      Resultkey = "\x1bOP";
      break;
    case "f2": // F2
      Resultkey = "\x1bOQ";
      break;
    case "f3": // F3
      Resultkey = "\x1bOR";
      break;
    case "f4": // F4
      Resultkey = "\x1bOS";
      break;
    case "f5": // F5
      Resultkey = "\x1bOT";
      break;
    case "f6": // F6
      Resultkey = "\x1bOU";
      break;
    case "f7": // F7
      Resultkey = "\x1bOV";
      break;
    case "f8": // F8
      Resultkey = "\x1bOW";
      break;
    case "f9": // F9
      Resultkey = "\x1bOX";
      break;
    case "f10": // F10
      Resultkey = "\x1bOY";
      break;
    case "f11": // F11
      Resultkey = "\x1bOZ";
      break;
    case "f12": // F12
      Resultkey = "\x1bO[";
      break;
    case "capslock": //CapsLock
      break;
    case "shiftleft": //shiftleft
      break;
    case "controlleft": //Control Left
      break;
    case "altleft": //altleft
      break;
    case "metaleft": //metaleft
      break;

    default:
      Resultkey = key;
      break;
  }
  // console.log("Virtual KeyBoard Key");
  // console.log(1, Resultkey);
  return Resultkey;
}

//get format current time
export function getFormattedTime() {
  var today = new Date();
  var y = today.getFullYear();
  // JavaScript months are 0-based.
  var m = today.getMonth() + 1;
  var d = today.getDate();
  var h = today.getHours();
  var mi = today.getMinutes();
  var s = today.getSeconds();
  return y + "-" + m + "-" + d + "_" + h + "-" + mi + "-" + s;
}

export const handleTimeData = (dataArray) => {
  for (let i = 0; i < dataArray.length; i++) {
    dataArray[i].timeDisplay = dataArray[i].time;
    dataArray[i].timeDisplay = format(
      new Date(dataArray[i].time),
      "MM/dd/yyyy HH:mm"
    );
  }
};
