const xss = require("xss");
require("dotenv").config();

class xssFilterService {
  removeHtmlTags(e) {
    //remove all html tag
    let filterResult2 = xss(e, {
      whiteList: {},
      stripIgnoreTag: true,
      stripIgnoreTagBody: ["script"],
    });

    return filterResult2;
  }
  containSpecialChar(e) {
    var alphanumers = /^[a-zA-Z0-9]+[a-zA-Z0-9\s._-]+$/;
    if (!alphanumers.test(e)) {
      return true;
    }
    return false;
  }

  hexContainSpecialChar(e) {
    var alphanumers = /^[a-zA-Z0-9]+$/;
    if (!alphanumers.test(e)) {
      return true;
    }
    return false;
  }
}

export default new xssFilterService();
