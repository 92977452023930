import axios from "axios";
import { lsDecrypt, lsEncrypt } from "../../utils/utils";
require("dotenv").config();

const REFRESH_TOKEN_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/refresh_token";

const CHNAGE_TOKEN_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/change_token";

class tokenService {
  getToken() {
    let token = lsDecrypt("user");
    const user = JSON.parse(token);
    return user?.token;
  }

  updateToken(token) {
    localStorage.removeItem("user");
    lsEncrypt("user", JSON.stringify({ token: token }));
  }

  changeToken(token, groupid) {
    return axios({
      method: "post",
      url: CHNAGE_TOKEN_URL,
      withCredentials: true,
      headers: {
        UserGroup: JSON.parse(localStorage.getItem("userGroup")),
        Authorization: `Bearer ${token}`,
      },
      data: { group_id: groupid },
    });
  }

  refreshToken() {
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(lsDecrypt("user"));

    const token = user.token;
    // console.log(token);

    return axios({
      method: "post",
      url: REFRESH_TOKEN_URL,
      withCredentials: true,
      headers: {
        UserGroup: JSON.parse(localStorage.getItem("userGroup")),
        Authorization: `Bearer ${token}`,
      },
      data: {},
    });
  }

  setUser(user) {
    lsEncrypt("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }
}

export default new tokenService();
