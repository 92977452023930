import axios from "axios";
import { getHeader } from "../../utils/utils";

require("dotenv").config();

const NODE_CONTROL_Info_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_control_info/nodes/";

const NODE_CONTROL_SETUP =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_control_setup/nodes/";

const NODE_CONTROL_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/nodes_control/nodes/";

class hostInfoService {
  getSMBIOSDigest(token, id) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/agent_info/smbios/digest",
      headers: getHeader(token),
      data: {},
    });
  }

  getSMBIOSType(token, id) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/agent_info/smbios/types",
      headers: getHeader(token),
      data: {},
    });
  }

  getSMBIOSTypeDetail(token, id, type) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/agent_info/smbios/" + type,
      headers: getHeader(token),
      data: {},
    });
  }

  setSystemReportPara(token, id, type) {
    // console.log("Set SystemReport Para");

    return axios({
      method: "put",
      url: NODE_CONTROL_SETUP + id + "/system_report",
      headers: getHeader(token),
      data: {
        type: type,
      },
    });
  }

  // use the same API with above setter. But the evt report will accept more parameter, I divided it into two setter
  setSystemEvtReportPara(token, id, time, log, level, count) {
    return axios({
      method: "put",
      url: NODE_CONTROL_SETUP + id + "/system_report",
      headers: getHeader(token),
      data: {
        type: "evt",
        time: time,
        // log: log,
        log_type: log,
        level: level,
        count: count,
      },
    });
  }

  getSystemReportInfo(token, id) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/system_report",
      headers: getHeader(token),
      data: {},
    });
  }

  getSystemReportList(token, id, type) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_URL + id + "/system_report/list/" + type,
      headers: getHeader(token),
      data: {},
    });
  }

  getSystemReportLog(token, id, type, rptId) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_URL + id + "/system_report/" + type + "/" + rptId,
      headers: getHeader(token),
      responseType: "blob",
      data: {},
    });
  }

  getWinAgentInfoByType(token, id, type) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/agent_info/" + type,
      headers: getHeader(token),
      data: {},
    });
  }

  getAgentStatusService(token, id) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/agent_info/service_status",
      headers: getHeader(token),
      data: {},
    });
  }

  getAgentInfoTypeSts(token, id, type) {
    return axios({
      method: "GET",
      url: NODE_CONTROL_Info_URL + id + "/agent_info_status/" + type,
      headers: getHeader(token),
      data: {},
    });
  }
}

export default new hostInfoService();
