import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { handleError } from "../../utils/utils";
import UserService from "../service/user.service";
import UserTableAdmin from "./UserTableAdmin";

const AddUserToGroupComponent = ({
  userGroupActiveModal,
  userOrgData,
  userGroupData,
  allUserListData,
  token,
}) => {
  const [activeModal, setActiveModal] = userGroupActiveModal;
  const [openNewGroupSetManager, setOpenNewGroupSetManager] = useState(false);
  const [userOrg, setUserOrg] = userOrgData;
  const [groupList, setGroupList] = userGroupData;
  const [allUserList, setAllUserList] = allUserListData;
  const [value, setValue] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectGroupMembers, setSelectGroupMembers] = useState([]);
  const [displayUserList, setDisplayUserList] = useState([]);
  const [selectGroupName, setSelectGroupName] = useState("");
  const history = useHistory();

  useEffect(() => {
    //Set the default value of select group
    setValue(groupList[0]._id);
    setSelectGroupName(groupList[0].name);
    //get the group members
    if (value !== null) {
      handleGetGroupMembers(value);
    }
  }, []);

  useEffect(() => {
    //select group value change, change the group user list
    if (value !== null) {
      handleGetGroupMembers(value);
    }
  }, [value]);

  useEffect(() => {
    if (selectGroupMembers.members) {
      setDisplayUserList(
        getNonGroupMembers(selectGroupMembers.members, allUserList)
      );
    }
  }, [selectGroupMembers]);

  //filter out members not in group
  function getNonGroupMembers(selectGroupMembers, allUserList) {
    const nonGroupMembers = allUserList.filter(
      (user) =>
        !selectGroupMembers.some((groupMember) => groupMember._id === user._id)
    );

    return nonGroupMembers;
  }

  const hendleCloseSetManagerWindow = (e) => {
    setOpenNewGroupSetManager(false);
  };

  const handleGetGroupMembers = (id) => {
    UserService.getUserGroupMember(token, id)
      .then((response) => {
        console.log(response.data);
        setSelectGroupMembers(response.data);
      })

      .catch((error) => {
        console.log(error);
        handleError(error, history, () => {
          handleGetGroupMembers(id);
        });
      });
  };

  const handleSetSelectGroup = (target) => {
    setValue(target.value);
    //save the group name, since if group name is admin,add user maanger will be default true
    let group = groupList.filter((obj) => obj._id === target.value);
    setSelectGroupName(group[0].name);
  };

  const handleCloseWindow = (e) => {
    //Set default value
    setValue(groupList[0]._id);
    setSelectedRows([]);
    setOpenNewGroupSetManager(false);
    setActiveModal(false);
  };

  //Handle adding users to a group
  const handleAddUser = () => {
    // let tempReverseUserList = [...displayUserList].reverse();
    let tempReverseUserList = [...displayUserList];
    const addPromises = selectedRows.map((rowIndex) => {
      return UserService.addUser(
        token,
        value,
        tempReverseUserList[rowIndex]._id,
        selectGroupName === "admin" ? true : false
      );
    });

    // wait for all addUser requests to complete
    Promise.all(addPromises)
      .then((responses) => {
        //console.log("All users Added successfully");
        swal("Success!", "Add Success!", "success");
        handleCloseWindow();
      })
      .catch((error) => {
        //console.error("Error deleting one or more users", error);
        swal("Error!", "Add Fail", "error");
      });
  };

  return (
    <>
      <Modal
        show={activeModal}
        onHide={handleCloseWindow}
        className="fade bd-example-modal-lg"
        id="addOrg"
        size="lg"
      >
        <Modal.Body className="card-header-tab">
          <div className="card">
            <Tab.Container defaultActiveKey="groupname">
              <div className="card-tab-shadow">
                <div className="card-header d-sm-flex d-block pb-0 border-0 card-header-tabtitle">
                  <Button
                    variant=""
                    className="close close-intab close-lg-display"
                    onClick={() => handleCloseWindow()}
                  >
                    <span>&times;</span>
                  </Button>
                  <div className="">
                    <h4 className="header-title fs-20 mt-2 ">
                      Add User To Group
                    </h4>
                  </div>
                  <div className="card-action  card-tabs2  ">
                    <Nav as="ul" className="nav nav-tabs" role="tablist">
                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="nav-item-tab"
                          data-toggle="tab"
                          eventKey="groupname"
                          onClick={(e) => hendleCloseSetManagerWindow(e)}
                        >
                          Group Name
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="nav-item-tab"
                          eventKey="adduser"
                          onClick={(e) => hendleCloseSetManagerWindow(e)}
                        >
                          Add Users
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Button
                    variant=""
                    className="close close-intab ml-5 close-btn-position"
                    onClick={() => handleCloseWindow()}
                  >
                    <span>&times;</span>
                  </Button>
                </div>
              </div>

              <div className="">
                <Tab.Content className="tab-content">
                  <Tab.Pane role="tabpanel" eventKey="groupname">
                    <div className="featured-menus owl-carousel">
                      <div className="items mt-5">
                        <div className="form-group  px-5 ">
                          <div className="form-group">
                            <label className="normal-text mt-3 ml-1">
                              Organization
                            </label>
                            <input
                              type="email"
                              className="form-control mb-3"
                              placeholder={userOrg}
                              disabled
                            />

                            <label className="normal-text ml-1">Group</label>
                            <select
                              className="form-control"
                              id="sel1"
                              value={value}
                              // onChange={(e) => setValue(e.currentTarget.value)}
                              onChange={(e) => {
                                handleSetSelectGroup(e.currentTarget);
                              }}
                            >
                              {groupList.map(({ name, _id }) => (
                                <option key={_id} value={_id}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane role="tabpanel" eventKey="adduser">
                    <div className="featured-menus owl-carousel ">
                      <div className="items">
                        <UserTableAdmin
                          userList={displayUserList}
                          selectRow={[selectedRows, setSelectedRows]}
                        />
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          className="btn btn-primary mr-4"
                          style={{
                            padding: "1rem 3rem",
                          }}
                          onClick={() => handleAddUser()}
                        >
                          Add User
                        </Button>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddUserToGroupComponent;
