import { createBrowserHistory } from "history";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { withResizeDetector } from "react-resize-detector";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavbarLanding from "../src/jsx/pages/navlanding-component";
import "./css/basic.css";
import "./css/style.css";
import ManageNodes from "./jsx/components/Node/ManageNodes";
// // Css
import "./index.css";
import ManageNodeCard from "./jsx/components/Node/NodeCards/ManageNodeCards";
import ManageUserGroup from "./jsx/components/UserGroup/ManageUserGroup";
import Footer from "./jsx/layouts/Footer";
// Layout
import { css } from "@emotion/react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import SquareLoader from "react-spinners/SquareLoader";
import Nav from "./jsx/layouts/nav";
import Error404 from "./jsx/pages/Error404";
import "./scss/styles/styles.css";

const ManageFW = React.lazy(() => import("./jsx/components/Node/FW/ManageFW"));

const ManageTaskComponent = React.lazy(() =>
  import("./jsx/components/Task/ManageTask-component")
);

const UserProfileComponent = React.lazy(() =>
  import("./jsx/components/Profile/UserProfile-component")
);

const Dashboard = React.lazy(() => import("./jsx/components/Dashboard"));

const UserList = React.lazy(() =>
  import("./jsx/components/UserGroup/UserList-component")
);

const UserJoinPendingComponent = React.lazy(() =>
  import("./jsx/components/UserGroup/UserJoinPending-component")
);

const ManageOrg = React.lazy(() =>
  import("./jsx/components/Organization/ManageOrg")
);

const SettingProvisionComponent = React.lazy(() =>
  import("./jsx/components/Set/SettingProvision-home")
);

const NodeJoinPending = React.lazy(() =>
  import("./jsx/components/Node/NodeJoinPending")
);

const Action = React.lazy(() =>
  import("./jsx/components/Node/Actions/ActionPage")
);

const HostInfo = React.lazy(() =>
  import("./jsx/components/Node/HostInfo/HostInfoMain")
);

const License = React.lazy(() => import("./jsx/components/Main/License"));
const LoginComponent = React.lazy(() => import("./jsx/pages/login-component"));
const Help = React.lazy(() => import("./jsx/components/Main/Help"));
const NodeStatus = React.lazy(() => import("./jsx/components/Node/NodeStatus"));
const Contact = React.lazy(() => import("./jsx/components/Main/Contact"));
const RestPassword = React.lazy(() => import("./jsx/pages/ResetPassword"));

const NotificationSetting = React.lazy(() =>
  import("./jsx/components/Set/notification/NotificationSetting")
);

const LineScreenshot = React.lazy(() =>
  import("./jsx/pages/lineLiff/LineScreenshot")
);
// const LineScreenshot1 = React.lazy(() =>
//   import("./jsx/pages/lineLiff/LineScreenshot2")
// );

const LinePowerCycle = React.lazy(() =>
  import("./jsx/pages/lineLiff/LinePowerCycle")
);

const LineNodeStatus = React.lazy(() =>
  import("./jsx/pages/lineLiff/LineNodeStatus")
);

const LiffBindPage = React.lazy(() =>
  import("./jsx/pages/lineLiff/LiffBindPage")
);
const override = css`
  display: block;
  margin: 0 auto;
  ${"" /* border-color: "#1FABAA"; */}
`;

const App = ({ width }) => {
  const history = useHistory();
  const body = document.querySelector("body");
  const [progress, setProgress] = useState(0);
  const previousScrollY = useRef();
  const [scrollDirection, setScrollDirection] = useState("down");
  const [scrollY, setScrollY] = useState(0);
  const [loginStatus, setLoginStatus] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#1FABAA");
  const [fadeState, setFadeState] = useState("");
  const [selectGroupLoad, setSelectGroupLoad] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    function listener() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setScrollY(scrollTop);
      setProgress(
        scrollTop / (document.documentElement.offsetHeight - window.innerHeight)
      );

      const direction = previousScrollY.current < scrollTop ? "down" : "up";
      setScrollDirection(direction);
    }

    function onTouchStart() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setScrollY(scrollTop);
      setProgress(
        scrollTop / (document.documentElement.offsetHeight - window.innerHeight)
      );

      const direction = previousScrollY.current < scrollTop ? "down" : "up";
      setScrollDirection(direction);
    }

    window.addEventListener("scroll", listener, { passive: true });

    document.addEventListener("touchstart", onTouchStart, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", listener, { passive: true });
      window.removeEventListener("touchstart", onTouchStart, { passive: true });
    };
  }, []);

  useEffect(() => {
    // if (selectGroupLoad) {
    //   setFadeState("fade-in");
    // } else {
    //   setFadeState("fadeOut");
    // }

    if (selectGroupLoad) {
      setFadeOut(true);
      setTimeout(() => {
        setSelectGroupLoad(false);
        setFadeOut(false);
      }, 2500);
    }
  }, [selectGroupLoad]);

  // let [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const routes = [
    { url: "manage-org", component: ManageOrg },
    { url: "dashboard", component: Dashboard },
    { url: "manage-usergroup", component: ManageUserGroup },
    { url: "node-status", component: NodeStatus },
    { url: "manage-nodes", component: ManageNodes },
    { url: "manage-fw", component: ManageFW },
    { url: "nodes-joinpending", component: NodeJoinPending },
    { url: "user-list", component: UserList },
    { url: "userjoin-pending", component: UserJoinPendingComponent },
    { url: "user-profile", component: UserProfileComponent },
    { url: "manage-task", component: ManageTaskComponent },
    { url: "setting-provision", component: SettingProvisionComponent },
    { url: "contact", component: Contact },
    { url: "error-404", component: Error404 },
    { url: "help", component: Help },
    { url: "license", component: License },
    { url: "manage-node-cards", component: ManageNodeCard },
    { url: "actions", component: Action },
    { url: "hostInfo", component: HostInfo },
    { url: "notification-setting", component: NotificationSetting },
    { url: "reset-password", component: RestPassword },
    { url: "liff/scrn", component: LineScreenshot },
    // { url: "liff/scrn1", component: LineScreenshot1 },
    { url: "liff/powercycle", component: LinePowerCycle },
    { url: "liff/nodestatus", component: LineNodeStatus },
    { url: "liff", component: LiffBindPage },
  ];

  width >= 1300
    ? body.setAttribute("data-sidebar-style", "full")
    : width <= 1299 && width >= 767
    ? body.setAttribute("data-sidebar-style", "mini")
    : body.setAttribute("data-sidebar-style", "overlay");

  return (
    <>
      {/* <div className="test_env">For Frontend & Backend Test</div> */}

      <div
        id="overlay"
        className={`loader-container ${fadeOut ? "fade-out" : ""}`}
        style={{ visibility: selectGroupLoad ? "" : "hidden" }}
      >
        {" "}
        <div className="loader-container">
          <SquareLoader
            color={color}
            loading={loading}
            css={override}
            size={50}
          />
          <p className="loading-text">Loading...</p>
        </div>
      </div>

      <Router history={history} basename="/">
        <div id="main-wrapper" className="show">
          <div id="nav-display" className="nav-display">
            <Nav
              login={[loginStatus, setLoginStatus]}
              reloadTest={[refreshKey, setRefreshKey]}
              load={[selectGroupLoad, setSelectGroupLoad]}
            />
          </div>
          <div id="content-body" className="content-body">
            <div id="container-fluid" className="container-fluid">
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={`${data.url}-${refreshKey}`}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    ></Route>
                  ))}

                  <Route path="/" exact>
                    <NavbarLanding />
                    <LoginComponent
                      // user={[currentUser, setCurrentUser]}
                      setLoginStatus={setLoginStatus}
                    />
                  </Route>

                  <Route path="/contact" exact>
                    <Contact />
                  </Route>

                  <Route path="/liff/scrn" exact>
                    <LineScreenshot />
                  </Route>

                  {/* <Route path="/liff/scrn1" exact>
                    <LineScreenshot1 />
                  </Route> */}

                  <Route path="/liff/powercycle" exact>
                    <LinePowerCycle />
                  </Route>

                  <Route path="/liff/nodestatus" exact>
                    <LineNodeStatus />
                  </Route>

                  <Route path="/liff/bindsuccess" exact>
                    <LiffBindPage />
                  </Route>

                  <Route path="/license" exact>
                    <License />
                  </Route>
                  <Route component={Error404} />
                </Switch>
              </Suspense>
            </div>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <Footer />
          </Suspense>
        </div>
      </Router>
    </>
  );
};

export default withResizeDetector(App);
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});
