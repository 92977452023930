import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import xssFilterService from "../../service/xssfilter.service";
import { searchCondition } from "../Helper/NodeHelper";
import OffCanvas from "./OffCanvasRight";
const NodeCardSearchBar = ({
  isReProvision,
  setSwitchToCard,
  isSmallScreen,
  setFilterConditionList,
  filterConditionList,
  cleanSearch,
  showOffCanvas,
  setShowOffCanvas,
  filterLabelList,
}) => {
  const [BIOSLabel, setBIOSLabel] = useState(
    "Basic Input/Output System Version"
  );
  const [fwVerLabel, setFWVerLabel] = useState("Firmware Version");
  const [osLabel, setOSLabel] = useState("Operating System Version");
  const [locationLabel, setLocationLabel] = useState("Location");
  const [subOrgLabel, setSubOrgLabel] = useState("Sub Organization");
  const [orgLabel, setOrgLabel] = useState("Organization");
  const [dataInput, setDataInput] = useState(false);
  const [hostList, setHostList] = useState(["Agent ON", "Agent OFF"]);
  const [eventList, setEventList] = useState([
    "All Event",
    "ACPI Event",
    "Sensor Event",
  ]);
  const [onLineList, setOnlineList] = useState(["Online", "Offline"]);
  const [hostStatus, setHostStatus] = useState("Host Status");
  const [eventStatus, setEventStatus] = useState("Event Status");
  const [onlineStatus, setOnlineStatus] = useState("Online Status");
  const [searchNodeNameValue, setSearchNodeNameValue] = useState("");
  const [inputText, setInputText] = useState("Search Node Name");
  const [clearButton, setClearButton] = useState(false);
  const [onLineSelect, setOnlineSelect] = useState(null);
  const [eventSelect, setEventSelect] = useState(null);
  const [hostSelect, setHostSelect] = useState(null);
  const [searchNameSelect, setSearchNameSelect] = useState(null);
  const [orgSelect, setOrgSelect] = useState(null);
  const [subOrgSelect, setSubOrgSelect] = useState(null);
  const [locationSelect, setLocationSelect] = useState(null);
  const [osSelect, setOsSelect] = useState(null);
  const [fwverSelect, setFWverSelect] = useState(null);
  const [biosSelect, setBIOSSelect] = useState(null);

  const firstUpdate = useRef(true);

  const setHostStatusValue = (e) => {
    setHostStatus(e);
  };
  const setEventStatusValue = (e) => {
    setEventStatus(e);
  };

  const setOnlineStatusValue = (e) => {
    setOnlineStatus(e);
  };

  const setSearchNodeName = (e) => {
    setSearchNodeNameValue(e);
  };

  const handleSwitchToList = (e) => {
    setSwitchToCard(false);
  };

  useEffect(() => {
    if (orgLabel !== "Organization") {
      let select = "";
      select = searchCondition("org", orgLabel, "eq");
      setOrgSelect(select);
    }
  }, [orgLabel]);

  useEffect(() => {
    if (subOrgLabel !== "Sub Organization") {
      let select = "";
      select = searchCondition("suborg", subOrgLabel, "eq");
      setSubOrgSelect(select);
    }
  }, [subOrgLabel]);

  useEffect(() => {
    if (locationLabel !== "Location") {
      let select = "";
      select = searchCondition("location", locationLabel, "eq");
      setLocationSelect(select);
    }
  }, [locationLabel]);

  useEffect(() => {
    if (osLabel !== "Operating System Version") {
      let select = "";
      select = searchCondition("osver", osLabel, "eq");
      setOsSelect(select);
    }
  }, [osLabel]);

  useEffect(() => {
    if (fwVerLabel !== "Firmware Version") {
      let select = "";
      select = searchCondition("fwver", fwVerLabel, "eq");
      setFWverSelect(select);
    }
  }, [fwVerLabel]);

  useEffect(() => {
    if (BIOSLabel !== "Basic Input/Output System Version") {
      let select = "";
      select = searchCondition("bios", BIOSLabel, "eq");
      setBIOSSelect(select);
    }
  }, [BIOSLabel]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      const delayDebounceFn = setTimeout(() => {
        let select = searchCondition("nodename", searchNodeNameValue, "lk");
        setSearchNameSelect(select);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchNodeNameValue]);

  useEffect(() => {
    if (
      hostStatus !== "Host Status" ||
      eventStatus !== "Event Status" ||
      onlineStatus !== "Online Status" ||
      searchNodeNameValue !== ""
    ) {
      setClearButton(true);
    } else {
      setClearButton(false);
    }
  }, [hostStatus, eventStatus, onlineStatus, searchNodeNameValue]);

  useEffect(() => {
    if (hostStatus !== "Host Status") {
      let select = "";
      if (hostStatus === "Agent ON") {
        select = searchCondition("host", "on", "eq");
      } else if (hostStatus === "Agent OFF") {
        select = searchCondition("host", "off", "eq");
      }

      setHostSelect(select);
    }
  }, [hostStatus]);

  useEffect(() => {
    if (eventStatus !== "Event Status") {
      let select = "";
      if (eventStatus === "All Event") {
        select = searchCondition("eventNum", 0, "gt");
        setEventSelect(select);
      } else {
        //wait for acpi or sensor event in the future
      }
    }
  }, [eventStatus]);

  useEffect(() => {
    if (onlineStatus !== "Online Status") {
      let select = "";
      if (onlineStatus === "Online") {
        select = searchCondition("onlineStatus", "true", "eq");
      } else if (onlineStatus === "Offline") {
        select = searchCondition("onlineStatus", "false", "eq");
      }
      setOnlineSelect(select);
    }
  }, [onlineStatus]);

  useEffect(() => {
    setFilterConditionList([]);
    if (onLineSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        onLineSelect,
      ]);
    }
    if (eventSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        eventSelect,
      ]);
    }
    if (hostSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        hostSelect,
      ]);
    }
    if (searchNameSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        searchNameSelect,
      ]);
    }

    if (orgSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        orgSelect,
      ]);
    }

    if (subOrgSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        subOrgSelect,
      ]);
    }

    if (locationSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        locationSelect,
      ]);
    }

    if (osSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        osSelect,
      ]);
    }

    if (biosSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        biosSelect,
      ]);
    }
    if (fwverSelect !== null) {
      setFilterConditionList((filterConditionList) => [
        ...filterConditionList,
        fwverSelect,
      ]);
    }
  }, [
    onLineSelect,
    eventSelect,
    hostSelect,
    searchNameSelect,
    biosSelect,
    fwverSelect,
    osSelect,
    locationSelect,
    subOrgSelect,
    orgSelect,
  ]);

  const handleCleanSearch = (e) => {
    setHostStatus("Host Status");
    setEventStatus("Event Status");
    setOnlineStatus("Online Status");
    setFilterConditionList([]);
    setOnlineSelect(null);
    setEventSelect(null);
    setHostSelect(null);
    setSearchNameSelect(null);
    setInputText("Search Node Name");
    setSearchNodeNameValue("");
    cleanSearch("");
    //reset filter from right canvas
    if (e === "Reset") {
      setOrgLabel("Organization");
      setLocationLabel("Location");
      setFWVerLabel("Firmware Version");
      setBIOSLabel("Basic Input/Output System Version");
      setSubOrgLabel("Sub Organization");
      setOSLabel("Operating System Version");
      setOrgSelect(null);
      setSubOrgSelect(null);
      setLocationSelect(null);
      setOsSelect(null);
      setFWverSelect(null);
      setBIOSSelect(null);
    }
  };

  //handle offcanvas open
  const handleOpen = () => {
    if (showOffCanvas) {
      setShowOffCanvas(false);
    } else {
      setShowOffCanvas(true);
    }
  };

  const closeCanvas = () => {
    setShowOffCanvas(false);
  };

  return (
    <>
      {!isSmallScreen && (
        <div
          className="card-dropdown-group col-12"
          style={{
            marginTop: isReProvision === true ? "0rem" : "1.5rem",
            paddingTop: isReProvision === true ? "2rem" : "0rem",
          }}
        >
          <div className="d-flex ">
            <div className="mr-2 ml-3">
              <Button
                as="a"
                className="more-filter-btn"
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleOpen()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
                <div>
                  {" "}
                  {!isSmallScreen && <span>More</span>}
                  &nbsp;<span>Filter</span>
                </div>
              </Button>
            </div>

            <div className="d-flex card-dropdown-item">
              <div className="mr-3">
                {" "}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="card-search-dropdown"
                    title="Dropdown button"
                  >
                    <div> {hostStatus} &nbsp; &nbsp;</div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {hostList.map((index, idx) => (
                      <Dropdown.Item
                        key={idx}
                        id={index}
                        onClick={() => setHostStatusValue(index)}
                      >
                        {index}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="mr-3">
                {" "}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    className="card-search-dropdown"
                    title="Dropdown end"
                    id="dropdown-menu-align-end"
                  >
                    <div> {eventStatus} &nbsp; &nbsp;</div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {eventList.map((index, idx) => (
                      <Dropdown.Item
                        key={idx}
                        id={index}
                        onClick={() => setEventStatusValue(index)}
                      >
                        {index}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div>
                {" "}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="card-search-dropdown"
                  >
                    <div> {onlineStatus} &nbsp; &nbsp;</div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {onLineList.map((index, idx) => (
                      <Dropdown.Item
                        key={idx}
                        id={index}
                        onClick={() => setOnlineStatusValue(index)}
                      >
                        {index}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-3">
                <div className="input-group search-area d-lg-inline-flex d-none  header-searchbox card-search-area">
                  <input
                    type="text"
                    className="form-control"
                    value={searchNodeNameValue}
                    placeholder={inputText}
                    onChange={(e) =>
                      setSearchNodeName(
                        xssFilterService.removeHtmlTags(e.target.value)
                      )
                    }
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              {clearButton && (
                <div className="clear-btn" onClick={handleCleanSearch}>
                  clear
                </div>
              )}
            </div>
          </div>

          {/* {!isReProvision && (
            <div className="grid-list-div">
              {" "}
              <Button
                to="/#"
                className="switch-Grid switch-padding-list"
                onClick={() => handleSwitchToList()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </Button>
              <Button
                to="/#"
                className="switch-Grid switch-padding-list"
                onClick={() => setSwitchToCard()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-grid-3x3-gap-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                </svg>
              </Button>
            </div>
          )} */}
        </div>
      )}
      {showOffCanvas && (
        <OffCanvas
          key={"end"}
          placement={"end"}
          isSmallScreen={isSmallScreen}
          host={[hostStatus, setHostStatus]}
          event={[eventStatus, setEventStatus]}
          online={[onlineStatus, setOnlineStatus]}
          handleCleanSearch={handleCleanSearch}
          closeCanvas={closeCanvas}
          bios={[BIOSLabel, setBIOSLabel]}
          fwVer={[fwVerLabel, setFWVerLabel]}
          os={[osLabel, setOSLabel]}
          location={[locationLabel, setLocationLabel]}
          suborg={[subOrgLabel, setSubOrgLabel]}
          org={[orgLabel, setOrgLabel]}
          inputStatus={[dataInput, setDataInput]}
          filterLabelList={filterLabelList}
        />
      )}
    </>
  );
};

export default NodeCardSearchBar;
