export const Permissions = {
  MANAGE_NODE_GROUPS: "manage node groups",
  MANAGE_USER_GROUPS: "manage user groups",
  MANAGE_NODES_CRITICAL_ACTION: "manage nodes critical action",
  MANAGE_NODES_NORMAL_ACTION: "manage nodes normal action",
  MANAGE_FW_AND_CERTIFICATE: "manage fw and certificate",
  MANAGE_PROVISION: "manage provision",
  FW_UPDATE: "fw update",
};

export const ErrorTypes = Object.freeze({
  REFRESH_TOKEN_EXPIRED: "Refresh Token Expired",
  REFRESH_TOKEN_SUCCESS: "Refresh token success",
  NOT_LOGGED_IN: "Not Login",
  CONCURRENT_ACTION_ERROR: "Function already enable.",
  HOSTINFO_SAMEACTION_ERROR: "Task is already working.",
});

export const PermissionColor = {
  MANAGE_NODE_GROUPS: "#8BC34A",
  MANAGE_USER_GROUPS: "#3E8F3F",
  MANAGE_NODES_CRITICAL_ACTION: "#25afa8",
  MANAGE_NODES_NORMAL_ACTION: "#007bb6",
  MANAGE_FW_AND_CERTIFICATE: "#33A6FF",
  MANAGE_PROVISION: "#8b85c9",
  FW_UPDATE: "#FDB813",
};

export const FWUpateSts = {
  0: "",
  100: "Download success.",
  200: "Update success.",
  "-1": "Unexpected error.",
  "-2": "Manifest download fail.",
  "-3": "Manifest format invalid.",
  "-4": "Manifest signature verify fail.",
  "-5": "Flash initial fail.",
  "-6": "Binary download fail.",
  "-7": "Digest of binary verify fail.",
  "-8": "Update fail.",
  "-100": "Update timeout",
};
