//Get all permissions from belongGroup

import ls from "localstorage-slim";
import swal from "sweetalert";
import ErrorService from "../components/service/error.service";
import TokenService from "../components/service/token.service";
import UserService from "../components/service/user.service";

// Function to get headers for HTTP requests
export const getHeader = (token, extraHeaders = {}) => {
  return {
    withCredentials: true,
    Authorization: `Bearer ${token}`,
    UserGroup: JSON.parse(localStorage.getItem("userGroup")),
    ...extraHeaders,
  };
};

// Function to get headers for HTTP requests
export const getLineHeader = (token, userGroupID, extraHeaders = {}) => {
  return {
    withCredentials: true,
    Authorization: `Bearer ${token}`,
    UserGroup: userGroupID,
    ...extraHeaders,
  };
};

// Function to check if the user group has admin permission
function checkUerGroupHasAdmin(belongGroup) {
  let hasAdmin = false;
  if (belongGroup !== undefined || belongGroup.length > 0) {
    for (let i = 0; i < belongGroup.length; i++) {
      const group = belongGroup[i];
      if (group.name === "admin") {
        hasAdmin = true;
        break;
      }
    }
  }
  return hasAdmin;
}

// Function to get all permissions for a user group
export async function getAllPermissions(belongGroup) {
  const userID = JSON.parse(localStorage.getItem("userID"));
  //console.log(userID);
  let permissionList = new Set();

  // Add admin permissions if user group has admin rights
  if (checkUerGroupHasAdmin(belongGroup)) {
    permissionList.add("manage user groups");
    permissionList.add("manage node groups");
  }

  // Get permissions for each group
  if (belongGroup !== undefined || belongGroup.length > 0) {
    for (let i = 0; i < belongGroup.length; i++) {
      const getPromiss = belongGroup.map((group) => {
        return UserService.getGroupMemberPermissions(
          TokenService.getToken(),
          group._id,
          userID
        );
      });

      // Await all promises for getting permissions
      try {
        const response = await Promise.all(getPromiss);
        //console.log(response);
        response.forEach((result, index) => {
          let tempList = result.data.permissions;
          // Add each permission to the permission list
          if (tempList.length > 0) {
            tempList.forEach((item) => permissionList.add(item));
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  }
  return [...permissionList];
}

// Function to get the user group data for user profile display
export function getTempBelongGroupData(belongGroup) {
  const tempBelongGroupData = [];

  // Iterate over each group and push to the temporary data array
  if (belongGroup !== undefined || belongGroup.length > 0) {
    for (let i = 0; i < belongGroup.length; i++) {
      const group = belongGroup[i];
      const permissions = group.permissions.toString().replace(/,/g, " / ");
      const isManager = group.isManager.toString();

      const tempGroup = {
        id: group._id,
        name: group.name,
        permission: permissions,
        isManager: isManager,
      };

      tempBelongGroupData.push(tempGroup);
    }
  }

  return tempBelongGroupData;
}

export function removeItems(itemList, itemsToRemove) {
  return itemList.filter((item) => !itemsToRemove.includes(item));
}

// Function to check if two arrays are equal
export function arraysEqual(a, b) {
  const setA = new Set(a);
  const setB = new Set(b);
  // If the sizes of both sets are not equal, then the arrays are not equal
  if (setA.size !== setB.size) {
    return false;
  }
  // For each item in setA, check if setB also has the item
  for (const item of setA) {
    if (!setB.has(item)) {
      return false;
    }
  }
  // If we passed the previous checks, the arrays are equal
  return true;
}
// Function to check if a certain permission exists in a set of permissions
export function checkPermission(permissions, permission, history) {
  // If permissions include the given permission, return true
  if (permissions.includes(permission)) {
    //console.log(permissions);
    return true;
  } else {
    // If not, show a permission warning
    permissionWarnings(history);
  }
}

// Function to display a permission warning and redirect to the dashboard
export function permissionWarnings(history) {
  swal(
    "It seems you don't have permission to access this page.",
    "Please contact your administrator.",
    "warning"
  ).then(() => {
    // Redirect to the dashboard after the user acknowledges the warning
    history.push("/dashboard");
  });
}

// Function to handle errors
export function handleError(error, history, retryFunction) {
  ErrorService.handleResponseError(error)
    .then((response) => {
      console.log("-------------------------------");
      console.log(response);
      console.log(response === "Action Unavailable Error");
      // Handle specific types of responses
      if (response === "Session expired") {
        swal(
          "Your session has expired!",
          "Please login again.",
          "warning"
        ).then(() => {
          localStorage.clear();
          // Redirect to login page
          history.push("/");
        });
      } else if (response === "Refresh token success") {
        // Retry the function
        retryFunction();
      } else if (response === "Not Login") {
        // console.log("Not Login");
        if (!JSON.parse(localStorage.getItem("change_Token"))) {
          swal(
            "It looks like you aren't logged in.",
            "Please log in to see this page.",
            "warning"
          ).then(() => {
            localStorage.clear();
            history.push("/");
          });
        }
      } else if (response === "Duplicate.") {
        //file duplicate
        //console.log("file duplicate");
        swal("Error!", "Duplicated name.", "error");
      } else if (response === "Verify signature failed.") {
        swal("Error!", "Upload Certificate Fail", "error");
      } else if (response === "Agent Empty") {
        //Initially, the Agent info is empty. It needs to wait until the node is online for the first time to post the Agent info.
        setTimeout(() => {
          retryFunction();
        }, 10000);
      } else if (response === "Action Unavailable Error") {
        ConcurrentError.showWarning();
      } else {
        if (!JSON.parse(localStorage.getItem("change_Token"))) {
          swal(
            "Something went wrong!",
            "Please contact your administrator.",
            "warning"
          );
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function checkUserLogin(history) {
  if (localStorage.getItem("userID")) {
    return true;
  } else {
    swal(
      "It looks like you aren't logged in.",
      "Please log in to see this page.",
      "warning"
    ).then(() => {
      history.push("/");
    });
  }
}

// enable encryption
// ls.config.encrypt = true;
// set a global secret
// ls.config.secret = "1234566";

export function lsEncrypt(key, value) {
  ls.set(key, value, { encrypt: true });
  //ls.set(key, value);
}

export function lsDecrypt(key) {
  return ls.get(key, { decrypt: true });
  // return ls.get(key);
}

export const ConcurrentWarnings = {
  showWarning: function () {
    return swal({
      title: "Concurrent Action Notice",
      content: {
        element: "div",
        attributes: {
          innerHTML:
            "<div style='text-align:center;'>This feature has been activated by another user. However, the same feature has been automatically activated for you.</div>",
        },
      },
      icon: "info",
    });
  },
};

export const ConcurrentError = {
  showWarning: function () {
    swal(
      "Concurrent Action Error!",
      "Please wait until the task is completed by others.",
      "error"
    );
  },
};

export const FunctionDeactivedWarning = {
  showWarning: function () {
    return swal({
      title: "Function Deactivated",
      content: {
        element: "div",
        attributes: {
          innerHTML:
            "<div style='text-align:center;'>This feature has been deactivated by another user. If you want to enable it, please reactivate it.</div>",
        },
      },
      icon: "warning",
    });
  },
};
