import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import logolight from "../../assets/images/logo.webp";

class NavbarLanding extends Component {
  prevScrollpos = 0;

  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "Login", navheading: "Login" },
        { id: 2, idnm: "Contact", navheading: "Contact" },
        { id: 3, idnm: "About", navheading: "About" },
      ],
      isOpen: false,
      topPos: "0",
      isOpenMenu: false,
      navCenterClass: "",
    };
    this.myRef = React.createRef(null);
    this.handleScrollMenu = this.handleScrollMenu.bind(this);
  }

  handleScrollMenu = async () => {
    let mounted = true;
    let currentScrollPos = window.pageYOffset;
    if (mounted) {
      if (this.prevScrollpos > currentScrollPos) {
        await this.setState({ topPos: "0" });
      } else {
        await this.setState({ topPos: "-420px" });
      }
      this.prevScrollpos = currentScrollPos;

      if (window.innerWidth <= 768) {
        await this.setState({ navCenterClass: "" });
      } else {
        await this.setState({
          navCenterClass: "navbar-nav mx-auto navbar-center",
        });
      }
    }
    return () => {
      mounted = false;
    };
  };

  componentDidMount() {
    this.prevScrollpos = window.pageYOffset;
    window.addEventListener("scroll", this.handleScrollMenu);
    if (window.innerWidth <= 768) {
      this.setState({ navCenterClass: "" });
    } else {
      this.setState({ navCenterClass: "navbar-nav mx-auto navbar-center" });
    }
  }

  toggle = async () => {
    await this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar expand="lg" sticky="top" id="navbar">
          <Container className="container-Nav">
            <NavbarBrand href="/" className="logo">
              <img
                src={logolight}
                alt="nmc logo"
                className="logo-light"
                height="60px"
                width="200px"
              />
            </NavbarBrand>

            <NavbarToggler
              className="navbar-toggler"
              type="button"
              aria-label="Toggle navigation"
              onClick={this.toggle}
            >
              <FontAwesomeIcon icon={faBars} color="#1FABAA" />
            </NavbarToggler>

            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              navbar
              ref={this.myRef}
            >
              <Nav
                navbar
                className="navbar-nav ml-auto navbar-right "
                id="mySidenav"
              >
                {this.state.navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    className={
                      item.navheading === "Login"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <NavLink
                      href={
                        item.navheading === "Login"
                          ? ""
                          : item.navheading === "About"
                          ? "https://ntccsapp.com/"
                          : item.idnm
                      }
                      className="nav-link home-nav-link"
                      style={{ color: "#1FABAA", textDecoration: "none" }}
                      to="/"
                    >
                      {" "}
                      {item.navheading}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default NavbarLanding;
