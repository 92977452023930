import React, { useEffect } from "react";
import { WithContext as ReactTags } from "react-tag-input";

const NodeGroupTag = ({ nodeGroup, dealTags }) => {
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [tags, setTags] = React.useState([]);

  useEffect(() => {
    let result = nodeGroup.tags.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
    let tagsArray = [];
    if (result !== null) {
      for (let i = 0; i < result.length; i++) {
        tagsArray.push({ id: result[i], text: result[i] });
      }
    }

    setTags(tagsArray);
  }, []);

  useEffect(() => {
    dealTags(tags);
  }, [tags]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    //console.log("The tag at index " + index + " was clicked");
  };

  return (
    <div className="app">
      <div>
        <ReactTags
          tags={tags}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          autoComplete
          inline
          inputFieldPosition="inline"
        />
      </div>
    </div>
  );
};

export default NodeGroupTag;
