import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useHistory } from "react-router-dom";
import onDeviceOffImg from "../../../../assets/images/node-card-device-off.png";
import onDeviceImg from "../../../../assets/images/node-card-device.png";
import offlineDeviceImg from "../../../../assets/images/node_device_offline.png";
import nodeSelectImg from "../../../../assets/images/node_device_select.png";
import hostOffLineDeviceImg from "../../../../assets/images/node_host_offline.png";
import onlineDeviceImg from "../../../../assets/images/provision_device.png";
import forceRemoveIcon from "../../../../assets/images/reProvision-immed-icon.png";
import modifyIcon from "../../../../assets/images/reProvision-modify-icon.png";
import responseRemoveIcon from "../../../../assets/images/reProvision-response-icon.png";
import { handleError, lsEncrypt } from "../../../utils/utils";
import HostInfoService from "../../service/hostinfo.service";
import TokenService from "../../service/token.service";

const NodeCard = ({
  isReProvision,
  mobileSelectNode,
  mobileSelectNodeListData,
  cardSelecNodeIDList,
  node,
  setSelectNodeName,
}) => {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);
  const [eventBadge, setEventBadge] = useState(false);
  const [deviceimg, setDeviceImg] = useState(onlineDeviceImg);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [boder, setBorder] = useState("1px solid #DDDDDD");
  const [textColor, setTextColor] = useState("#25AFA8");
  const [popOverShowTime, setPopOverShowTime] = useState(900);
  const [onlineStatusLabel, setOnlineStatusLabel] = useState("");
  const [mobileSelectNodeList, setMobileSelectNodeList] =
    mobileSelectNodeListData;
  const [modalStatusDisplay, setModalStatusDisplay] = useState({
    color: "#EE6164",
  });

  const [overLayTriggerStatus, setOverLayTriggerStatus] = useState([
    "hover",
    "hover",
  ]);

  const [titleBackground, setTitleBackground] = useState(
    "linear-gradient(90deg,rgba(36,174,169,1), rgba(104,215,149,1))"
  );

  const [circleLight, setCircleLight] = useState("#76ff84");
  const [btnAction, setBtnAction] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [openMobileNodeModal, setOpenMobileNodeModal] = useState(false);
  const [modalCardName, setModalCardName] = useState({
    color: "#25AFA8",
  });

  const [selectNode, setSelectNode] = useState(false);
  const [cardBoxShadow, setCardBoxShadow] = useState(
    "1px 1px 2px 1px rgba(0, 0, 0, 0.04)"
  );

  const [cardSelectNodeIDList, setCardSelectNodeIDList] = cardSelecNodeIDList;
  const [reProvisionImg, setReProvisionImg] = useState(null);

  const popover = (
    <Popover id="node-card-popover">
      <Popover.Header style={{ backgroundImage: titleBackground }}>
        {" "}
        <div className="node-popover-title-div">
          {" "}
          <div className="node-popover-title">{node.nodename} </div>
          <div
            className="node-card-light"
            style={{ background: circleLight }}
          ></div>
        </div>
      </Popover.Header>
      <Popover.Body>
        <div className="popover-body-div">
          <div className="node-popover-text">
            {" "}
            <div>{node.org}</div>
            <div>{node.suborg}</div>
            <div>{node.location}</div>
          </div>
          <div className="popover-body-r">
            <div className="popover-online-status" style={modalStatusDisplay}>
              {onlineStatusLabel}
            </div>

            {btnAction && (
              <Button className="popover-btn" onClick={toActions}>
                <div>Action</div>
              </Button>
            )}
            {!btnAction && (
              <Button className="popover-btn-info" onClick={toActions}>
                <div>Info</div>
              </Button>
            )}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const [popOverWindow, setPopOverWindow] = useState(popover);

  function toActions() {
    lsEncrypt("node", JSON.stringify(node));
    history.push({
      pathname: "/actions",
      state: {
        node: node,
      },
    });
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userGroupName")) === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    if (node.eventNum > 0) {
      setEventBadge(true);
    }
    //initial display
    nodeOnlineStatus();

    if (node.provision !== undefined) {
      if (node.provision.mode) {
        if (node.provision.mode === "modify") {
          setReProvisionImg(modifyIcon);
        } else if (node.provision.mode === "clear_response") {
          setReProvisionImg(responseRemoveIcon);
        } else if (node.provision.mode === "clear_immediate") {
          setReProvisionImg(forceRemoveIcon);
        }
      }
    }

    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 1280) {
      setIsSmallScreen(true);
    }
    handleResize();
  }, []);

  useEffect(() => {
    if (mobileSelectNode) {
    } else {
      //cancel select nodes
      setCardBoxShadow("1px 1px 2px 1px rgba(0, 0, 0, 0.04)");
      setSelectNode(false);
    }
  }, [mobileSelectNode]);

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setIsSmallScreen(true);
      setOverLayTriggerStatus(["click", "click"]);
    } else {
      setIsSmallScreen(false);
      setOverLayTriggerStatus(["hover", "hover"]);
    }
  };

  const selectNodeCard = () => {
    // set reprovision's select node name
    if (isReProvision) {
      setSelectNodeName(node.nodename);
    }

    if (backgroundColor !== "#25AFA8") {
      setBackgroundColor("#25AFA8");
      setTextColor("#FFFFFF");
      setDeviceImg(nodeSelectImg);
      setPopOverShowTime(2000);
      //mobile click open modal
      if (isSmallScreen && !isReProvision) {
        setOpenMobileNodeModal(true);
        setModalShow(true);
      } else {
        //desktop click add node to select list
        addSelectNodeToList();
      }
    } else {
      //deselect node
      nodeOnlineStatus();
      setPopOverShowTime(900);
      if (isSmallScreen) {
        setOpenMobileNodeModal(false);
        setModalShow(false);
      } else {
        //desktop click remove node from select list
        removeSelectNodeFromList();
      }
    }
  };

  const nodeOnlineStatus = () => {
    if (node.onlineStatus === "true") {
      setBackgroundColor("#FFFFFF");
      setDeviceImg(onlineDeviceImg);
      setTextColor("#25AFA8");

      setOnlineStatusLabel("");
      setBtnAction(true);
      setModalCardName({
        color: "#25AFA8",
      });

      getAgentStatus();
    } else if (node.onlineStatus === "false") {
      setBackgroundColor("#DBDBDB");
      setDeviceImg(offlineDeviceImg);
      setTextColor("#A4A4A4");
      setModalStatusDisplay({
        color: "#969BA0",
        fontWeight: 600,
      });
      setCircleLight("#909090");

      if (isSmallScreen) {
        setTitleBackground(
          "linear-gradient(90deg,rgba(42,178,168,1), rgba(42,178,168,1))"
        );
      } else {
        setTitleBackground(
          "linear-gradient(90deg,rgba(196,196,196,1), rgba(196,196,196,1))"
        );
      }
      setOnlineStatusLabel("Offline");
      setModalCardName({
        color: "#6A6969",
      });
      setBtnAction(false);
    }
  };

  const addSelectNodeToList = () => {
    let obj = {
      id: node.id,
      nodename: node.nodename,
      org: node.org,
      suborg: node.subOrg,
      location: node.location,
    };
    setMobileSelectNodeList((mobileSelectNodeList) => [
      ...mobileSelectNodeList,
      obj,
    ]);

    setCardSelectNodeIDList((cardSelectNodeIDList) => [
      ...cardSelectNodeIDList,
      node.id,
    ]);
  };

  const removeSelectNodeFromList = () => {
    let findIndexArray = Array.from(cardSelectNodeIDList);
    const index = findIndexArray.indexOf(node.id);
    if (index > -1) {
      findIndexArray.splice(index, 1); // 2nd parameter means remove one item only
      setCardSelectNodeIDList(findIndexArray);
    }

    let tempArray = Array.from(mobileSelectNodeList);
    tempArray.splice(index, 1);
    setMobileSelectNodeList(tempArray);
  };

  const handleSelectNode = () => {
    //console.log("handle select node");
    if (selectNode) {
      setSelectNode(false);
      setCardBoxShadow("1px 1px 2px 1px rgba(0, 0, 0, 0.04)");
      removeSelectNodeFromList();
    } else {
      setSelectNode(true);
      setCardBoxShadow("#37d1bfb5 1px 1px 2px 1px");
      addSelectNodeToList();
    }
  };

  const getAgentStatus = () => {
    HostInfoService.getAgentStatusService(TokenService.getToken(), node.id)
      .then((response) => {
        console.log(response);
        if (response.data.agent === "T") {
          setCircleLight("#76ff84");
        } else if (response.data.agent === "F") {
          //node Agent === off
          setCircleLight("#909090");
          setBackgroundColor("#FFFFFF");
          setDeviceImg(hostOffLineDeviceImg);
          setTextColor("#25AFA8");

          setModalStatusDisplay({
            color: "#EE6164",
            fontWeight: 600,
          });
          setOnlineStatusLabel("Agent OFF");
          setBtnAction(true);
        }
      })
      .catch((error) => {
        console.log(error);
        // console.log(error.response.status);
        handleError(error, history, () => {
          getAgentStatus();
        });
      });
  };

  return (
    <>
      {!isSmallScreen && !isReProvision && (
        <OverlayTrigger
          // trigger={isAdmin ? "" : overLayTriggerStatus}
          trigger={overLayTriggerStatus}
          placement="top"
          overlay={popover}
          style={{ margin: 0 }}
          delay={{ show: popOverShowTime, hide: 1500 }}
        >
          <div
            className="node-card"
            style={{
              backgroundColor: backgroundColor,
              cursor: "pointer",
              border: boder,
            }}
            onClick={selectNodeCard}
          >
            {" "}
            <div className="node-card-img">
              {eventBadge && (
                <span className="badge light text-white bg-red">
                  {node.eventNum}
                </span>
              )}

              <img
                src={deviceimg}
                alt="Device_image"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div style={{ color: textColor }}>{node.nodename}</div>
          </div>
        </OverlayTrigger>
      )}

      {isSmallScreen && !isReProvision && (
        <div
          className="node-card"
          style={{
            backgroundColor: backgroundColor,
            cursor: "pointer",
            border: boder,
            boxShadow: cardBoxShadow,
          }}
          onClick={selectNodeCard}
        >
          {mobileSelectNode && (
            <input
              className="node-card-check"
              type="checkbox"
              onChange={() => handleSelectNode()}
              checked={selectNode}
              onClick={(event) => event.stopPropagation()}
            />
          )}

          <div className="node-card-img">
            {eventBadge && (
              <span className="badge light text-white bg-red">
                {node.eventNum}
              </span>
            )}

            <img
              src={deviceimg}
              alt="Device_image"
              className="img-fluid mx-auto d-block"
            />
          </div>
          <div style={{ color: textColor }}>{node.nodename}</div>
        </div>
      )}

      {isReProvision && (
        <div
          className="node-card"
          style={{
            backgroundColor: backgroundColor,
            cursor: "pointer",
            border: boder,
          }}
          onClick={selectNodeCard}
        >
          {" "}
          <div className="node-card-img">
            <span className="badge light text-white rep-icon">
              <img src={reProvisionImg} alt="" className="" />
            </span>
            <img
              src={deviceimg}
              alt="Device_image"
              className="img-fluid mx-auto d-block"
            />
          </div>
          <div style={{ color: textColor }}>{node.nodename}</div>
        </div>
      )}

      {openMobileNodeModal && !isReProvision && (
        <Modal
          show={modalShow}
          onHide={() => selectNodeCard()}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nodecard-mobile-modal"
        >
          <Modal.Body>
            <div className="node-card-modal-head">
              <div>
                {" "}
                <div
                  className="node-card-light"
                  style={{ background: circleLight }}
                ></div>
                <div className="ml-1" style={modalStatusDisplay}>
                  {" "}
                  {onlineStatusLabel}
                </div>
              </div>

              <div className="card-close" onClick={selectNodeCard}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg mr-3"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                  />
                </svg>
              </div>
            </div>
            <div className="node-card-modal-div">
              {" "}
              <img
                src={btnAction ? onDeviceImg : onDeviceOffImg}
                alt="Device_image"
              />
            </div>
            <div className="node-popover-text node-popover-modal">
              {" "}
              <div style={modalCardName}>{node.nodename} </div>
              <div>{node.org}</div>
              <div>{node.suborg}</div>
              <div>{node.location}</div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ backgroundImage: titleBackground }}>
            <div className="footer-action">
              {btnAction && <div onClick={toActions}>Action {`>`}</div>}
              {!btnAction && <div onClick={toActions}>Info {`>`}</div>}
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default NodeCard;
