import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { default as Loader } from "react-loader-spinner";
import { useHistory } from "react-router";
import { Permissions } from "../../../utils/constants";
import { checkUserLogin, handleError, lsDecrypt } from "../../../utils/utils";
import NodeService from "../../service/node.service";
import TokenService from "../../service/token.service";
import {
  getAbandonNode,
  getReadyToReProvisionNode,
} from "../../Set/ProvisionHelper";
import {
  getSearchLabelList,
  handleNodeData,
  search,
} from "../Helper/NodeHelper";
import NodeCardGroup from "./NodeCardGroup";
import NodeCardSearchBar from "./NodeCardSearchBar";

const ManageNodeCards = ({
  isReProvision,
  setSwitchToCard,
  setShowOffCanvas,
  showOffCanvas,
  mobileSelectNode,
  mobileSelectNodeList,
  cardSelecNodeIDList,
  setAllNodeList,
  setSelectNodeName,
  isReadyToReProvision,
  setReadyToReProvisionList,
  isAbandonNode,
  setAbandonNodeList,
  setDataLoad,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [nodeDataList, setNodeDataList] = useState([]);
  const [nodeFilterList, setNodeFilterList] = useState([]);
  const [filterConditionList, setFilterConditionList] = useState([]);
  const [filterLabelList, setFilterLabelList] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const history = useHistory();

  let getNodeStatusTimerPeriod = 1000;
  const timer = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 1380) {
      setIsSmallScreen(true);
    }

    if (checkUserLogin(history)) {
      handleGetNodeList();
      setLoadingStatus(true);
    }

    return () => {
      clearInterval(timer.current);
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    if (!refresh) {
      setRefresh(true);
    }
  }, [refresh]);

  useEffect(() => {
    if (filterConditionList.length > 0) {
      search(nodeFilterList, filterConditionList).then((filterResult) => {
        setRefresh(false);
        setNodeDataList(filterResult);
      });
    }
  }, [filterConditionList]);

  //get node from server
  const handleGetNodeList = () => {
    console.log("123456789");
    //if user is admin, select the admin's(all) node
    // if (
    //   JSON.parse(localStorage.getItem("userPermission")).permission.includes(
    //     Permissions.MANAGE_NODE_GROUPS
    //   )
    // )

    if (
      JSON.parse(lsDecrypt("userPermission")).permission.includes(
        Permissions.MANAGE_NODE_GROUPS
      )
    ) {
      handleGetAdminNodeList();
    } else {
      //otherwise, select the user's node
      let userGroupID = JSON.parse(localStorage.getItem("userGroup"));
      // let userGroupID = JSON.parse(lsDecrypt("userGroup"));
      // handleGetNodeByUserGroup(userGroupID);
      // need to do
      SetGetNodeOnline(userGroupID);
    }

    //for main node manage page loading status text
    if (setDataLoad) {
      setDataLoad(true);
    }
  };

  const SetGetNodeOnline = (userGroupID) => {
    if (timer.current) return;

    // timer.current = setInterval(function () {
    handleGetNodeByUserGroup(userGroupID);
    // }, getNodeStatusTimerPeriod);
  };

  const handleGetAdminNodeList = () => {
    NodeService.getNodeList(TokenService.getToken())
      .then((response) => {
        // console.log("Get node list:");
        console.log(response);

        let result = JSON.parse(JSON.stringify(response.data.nodes));
        handleGetNodeDetailList(result);
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          handleGetAdminNodeList();
        });
      });
  };

  const handleGetNodeByUserGroup = (userGroupid) => {
    // let nodeGroups = JSON.parse(localStorage.getItem("nodeGroups"));
    let nodeGroups = JSON.parse(lsDecrypt("nodeGroups"));

    if (nodeGroups?.nodeGroup?.length === 0) {
      handleGetNodeDetailList([]);
    } else {
      NodeService.getNodeByUserGroup(TokenService.getToken(), userGroupid)
        .then((response) => {
          console.log("Get node list:");
          console.log(response);
          let result = JSON.parse(JSON.stringify(response.data.nodes));
          handleGetNodeDetailList(result);
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, () => {
            handleGetNodeByUserGroup(userGroupid);
          });
        });
    }
  };

  const handleGetNodeDetailList = (nodelist) => {
    setFilterLabelList([]);
    if (nodelist.length > 0) {
      NodeService.getNodeArray(TokenService.getToken(), nodelist)
        .then((response) => {
          console.log(response);
          handleNodeData(response.data).then((result) => {
            handleSetDisplayList(result);
            let labelList = getSearchLabelList(result);
            setFilterLabelList(labelList);
          });
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, () => {
            handleGetNodeDetailList(nodelist);
          });
        });
    } else {
      handleSetDisplayList([]);
      setFilterLabelList([]);
    }
  };

  //control all display node cards
  const handleSetDisplayList = (result) => {
    //all nodes
    if (!isAbandonNode && !isReadyToReProvision) {
      //console.log(result);
      setNodeDataList(result);
      setNodeFilterList(result);
    }

    if (isReProvision) {
      //for display tab's node numbers
      setAllNodeList(result);
      let readyToProvisionNode = getReadyToReProvisionNode(result);
      setReadyToReProvisionList(readyToProvisionNode);

      //is ReadyToProvision
      if (isReadyToReProvision) {
        setNodeDataList(readyToProvisionNode);
        setNodeFilterList(readyToProvisionNode);
        //console.log(readyToProvisionNode);
      } else if (isAbandonNode) {
        // is abandon node
        getAbandonNode().then((abandonNodelist) => {
          // console.log(abandonNodelist);
          if (abandonNodelist === "error") {
          } else {
            const handleGetNodeArray = () => {
              NodeService.getNodeArray(TokenService.getToken(), abandonNodelist)
                .then((response) => {
                  console.log(response);
                  console.log(response.data);

                  handleNodeData(response.data).then((result) => {
                    setNodeDataList(result);
                    setNodeFilterList(result);
                    setAbandonNodeList(result);
                  });
                })
                .catch((error) => {
                  console.log(error.response);
                  handleError(error, history, () => {
                    handleGetNodeArray();
                  });
                });
            };
            handleGetNodeArray();
          }
        });
      }
    }
    setLoadingStatus(false);
  };

  useEffect(() => {
    setRefresh(true);
  }, [nodeDataList]);

  const handleResize = () => {
    if (window.innerWidth < 1380) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const cleanSearch = (e) => {
    setRefresh(false);
    handleSetDisplayList(nodeFilterList);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Manage Nodes - NMC Web Monitor</title>
          <meta name="description" content="Manage Nodes" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      <NodeCardSearchBar
        setSwitchToCard={setSwitchToCard}
        isSmallScreen={isSmallScreen}
        setFilterConditionList={setFilterConditionList}
        filterConditionList={filterConditionList}
        cleanSearch={cleanSearch}
        setShowOffCanvas={setShowOffCanvas}
        showOffCanvas={showOffCanvas}
        filterLabelList={filterLabelList}
        isReProvision={isReProvision}
      />

      {loadingStatus && (
        <div className="provision-loader-div">
          <Loader
            className=""
            type="TailSpin"
            color="#25AFA8"
            height={100}
            width={100}
          />
        </div>
      )}
      {refresh && (
        <NodeCardGroup
          isReProvision={isReProvision}
          nodeCardList={nodeDataList}
          mobileSelectNode={mobileSelectNode}
          mobileSelectNodeList={mobileSelectNodeList}
          cardSelecNodeIDList={cardSelecNodeIDList}
          setSelectNodeName={setSelectNodeName}
        />
      )}
    </>
  );
};

export default ManageNodeCards;
