import axios from "axios";
import { getHeader } from "../../utils/utils";
require("dotenv").config();
const API_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/permissions";
const IPLOOKUP_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/ip_lookup";

class otherService {
  getPermissionsList(token) {
    return axios.get(API_URL, {
      headers: getHeader(token),
    });
  }

  ipLookUp(token, hostname) {
    return axios({
      method: "post",
      url: IPLOOKUP_URL,
      headers: getHeader(token),
      data: { hostname: hostname },
    });
  }
}

export default new otherService();
