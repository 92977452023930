import { MDBDatatable } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MultiSelectDropdown from "./MultiSelectDropdown";

const UserTable = ({
  userList,
  selectRow,
  groupPermission,
  canEditPermissions,
  isInAdminGroup,
}) => {
  const [actionData, setActionData] = useState(null);
  const [open, setOpen] = useState(false);
  const [displayUserList, setDisplayUserList] = useState(userList);
  const [editUserSelectRows, setEditUserSelectRows] = selectRow;

  const handleDeleteUser = (indexToRemove) => {
    //record the id of the user to be deleted, once user click on the save window, it will trigger delete, and delete by the record list
    const removeIdRecord = [
      ...editUserSelectRows,
      displayUserList[indexToRemove]._id,
    ];
    setEditUserSelectRows(removeIdRecord);

    //update the display list
    const newList = [...displayUserList];
    newList.splice(indexToRemove, 1);
    setDisplayUserList(newList);
  };

  useEffect(() => {
    let basicColumns = [
      { label: "Manager", field: "isManager", sort: false },
      { label: "Email ", field: "email", width: 220 },
    ];

    if (canEditPermissions) {
      basicColumns.push({
        label: "Permission",
        field: "permission",
        width: 490,
      });
    }
    if (isInAdminGroup) {
      basicColumns.push({ label: "Delete", field: "delete", sort: false });
    }

    if (canEditPermissions)
      setActionData({
        columns: basicColumns,
        rows: displayUserList.map((row, index) => {
          return {
            ...row,

            isManager: (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={row.isManager}
                  onChange={() => {
                    const newList = [...displayUserList];
                    newList[index].isManager = !newList[index].isManager;
                    if (newList[index]["managerChanged"]) {
                      newList[index]["managerChanged"] = false;
                    } else {
                      newList[index]["managerChanged"] = true;
                    }

                    setDisplayUserList(newList);
                  }}
                />
              </div>
            ),

            permission: (
              <div>
                <MultiSelectDropdown
                  row={row}
                  index={index}
                  groupPermission={groupPermission}
                />
              </div>
            ),
            delete: (
              <>
                <Button
                  as="a"
                  className="btn-node-edit nmc-btn-p"
                  data-toggle="modal"
                  data-target="#addOrg"
                  onClick={() => handleDeleteUser(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#25AFA8"
                    className="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </Button>
              </>
            ),
          };
        }),
      });
  }, []);

  useEffect(() => {
    if (actionData !== null) {
      setOpen(true);
    }
  }, [actionData]);

  useEffect(() => {
    handleUpdateData();
  }, [groupPermission]);

  const handleUpdateData = () => {
    let basicColumns = [
      { label: "Manager", field: "isManager", sort: false },
      { label: "Email ", field: "email", width: 220 },
    ];

    if (canEditPermissions) {
      basicColumns.push({
        label: "Permission",
        field: "permission",
        width: 490,
      });
    }
    if (isInAdminGroup) {
      basicColumns.push({ label: "Delete", field: "delete", sort: false });
    }

    setActionData({
      columns: basicColumns,
      rows: displayUserList.map((row, index) => {
        return {
          ...row,
          isManager: (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked={row.isManager}
                onChange={() => {
                  const newList = [...displayUserList];
                  newList[index].isManager = !newList[index].isManager;
                  if (newList[index]["managerChanged"]) {
                    newList[index]["managerChanged"] = false;
                  } else {
                    newList[index]["managerChanged"] = true;
                  }

                  setDisplayUserList(newList);
                }}
              />
            </div>
          ),
          permission: (
            <div>
              <MultiSelectDropdown
                row={row}
                index={index}
                groupPermission={groupPermission}
              />
            </div>
          ),
          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit nmc-btn-p"
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDeleteUser(index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#25AFA8"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });
  };

  useEffect(() => {
    setDisplayUserList(userList);
  }, [userList]);

  useEffect(() => {
    handleUpdateData();
  }, [displayUserList]);

  return (
    <>
      {open && (
        <div
          className="edit-group-table"
          style={{ margin: "2.5rem 2rem 2rem 2rem" }}
        >
          <MDBDatatable
            search
            multi
            hover
            data={actionData}
            noFoundMessage="No matching members found."
            advancedData
            entries={4}
            entriesOptions={[4, 8, 12]}
            format={(field: string, value: any) => {
              if (field === "email") {
                return {
                  fontSize: "15.5px",
                };
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default UserTable;
