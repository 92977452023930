import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Permissions } from "../../utils/constants";
import { arraysEqual } from "../../utils/utils";

const MultiSelectDropdown = ({ row, index, groupPermission }) => {
  const [selectedPermissions, setSelectedPermissions] = useState(
    row["permissions"] || []
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [defaultPermissions, setDefaultPermissions] = useState([]);
  const [recordDelPermission, setRecordDelPermission] = useState([]);
  const dropdownRef = useRef(null);

  // set the permission
  const togglePermission = (permission, event) => {
    //prevent only select normal action permission will bubble up to the parent(critical action permission)
    if (event) {
      event.stopPropagation();
    }

    //if permission is in the list, remove it, else add it
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions((prevState) => {
        const newPermissions = prevState.filter((perm) => perm !== permission);
        // User can not remove normal action permission if critical action permission is selected
        if (
          permission === Permissions.MANAGE_NODES_NORMAL_ACTION &&
          newPermissions.includes(Permissions.MANAGE_NODES_CRITICAL_ACTION)
        ) {
          Swal.fire({
            icon: "warning",
            text: "With 'Manage Nodes Critical Action' permission, you can't remove 'Manage Nodes Normal Action' individually.",
          });
          return prevState;
        }

        return newPermissions;
      });
    } else {
      setSelectedPermissions((prevState) => {
        const newPermissions = [...prevState, permission];
        if (
          permission === Permissions.MANAGE_NODES_CRITICAL_ACTION &&
          !newPermissions.includes(Permissions.MANAGE_NODES_NORMAL_ACTION)
        ) {
          newPermissions.push(Permissions.MANAGE_NODES_NORMAL_ACTION);
        }
        return newPermissions;
      });
    }
  };

  const updateChangedRows = (newPermissions) => {
    // Check if permissions have changed
    const hasPermissionsChanged = !arraysEqual(
      newPermissions,
      defaultPermissions
    );

    return hasPermissionsChanged;
  };

  useEffect(() => {
    //record old permissions for comparison
    setDefaultPermissions(row["permissions"]);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    row["permissions"] = selectedPermissions;
    row["permissionsChanged"] = updateChangedRows(selectedPermissions);
  }, [selectedPermissions]);

  useEffect(() => {
    for (let i = 0; i < defaultPermissions.length; i++) {
      if (!groupPermission.includes(defaultPermissions[i])) {
        let newPermissions = [...recordDelPermission];
        newPermissions.push(defaultPermissions[i]);
        setRecordDelPermission(newPermissions);
      }
    }
  }, [defaultPermissions]);

  useEffect(() => {
    for (let i = 0; i < recordDelPermission.length; i++) {
      const isEqual = (element) => {
        return element === recordDelPermission[i];
      };

      let index = selectedPermissions.findIndex(isEqual);

      if (index !== -1) {
        selectedPermissions.splice(index, 1);
        let newList = Array.from(selectedPermissions);

        setSelectedPermissions(newList);
      }
    }
  }, [recordDelPermission]);

  return (
    <div className="multi-select-dropdown" ref={dropdownRef}>
      <div
        className="selected-permissions"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {selectedPermissions.length === 0
          ? "Select permissions"
          : selectedPermissions.join(" / ")}
      </div>
      {isDropdownOpen && (
        <ul className="permissions-list">
          {groupPermission.map((permission) => {
            if (permission === Permissions.MANAGE_NODES_CRITICAL_ACTION) {
              return (
                <li
                  key={permission}
                  onClick={(e) =>
                    togglePermission(
                      Permissions.MANAGE_NODES_CRITICAL_ACTION,
                      e
                    )
                  }
                  className={
                    selectedPermissions.includes(permission) ? "selected" : ""
                  }
                >
                  {selectedPermissions.includes(permission) && (
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  )}
                  {permission}
                  <ul className="sub-permissions-list">
                    <li
                      key={Permissions.MANAGE_NODES_NORMAL_ACTION}
                      onClick={(e) =>
                        togglePermission(
                          Permissions.MANAGE_NODES_NORMAL_ACTION,
                          e
                        )
                      }
                      className={
                        selectedPermissions.includes(
                          Permissions.MANAGE_NODES_NORMAL_ACTION
                        )
                          ? "selected"
                          : ""
                      }
                    >
                      {" "}
                      {selectedPermissions.includes(
                        Permissions.MANAGE_NODES_NORMAL_ACTION
                      ) && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="check-icon"
                        />
                      )}
                      manage nodes normal action
                    </li>
                  </ul>
                </li>
              );
            } else if (permission === Permissions.MANAGE_NODES_NORMAL_ACTION) {
              if (
                groupPermission.includes(
                  Permissions.MANAGE_NODES_CRITICAL_ACTION
                )
              ) {
                return null;
              } else {
                return (
                  <li
                    key={permission}
                    onClick={(e) => togglePermission(permission, e)}
                    className={
                      selectedPermissions.includes(permission) ? "selected" : ""
                    }
                  >
                    {selectedPermissions.includes(permission) && (
                      <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    )}
                    {permission}
                  </li>
                );
              }
            } else {
              return (
                <li
                  key={permission}
                  onClick={() => togglePermission(permission)}
                  className={
                    selectedPermissions.includes(permission) ? "selected" : ""
                  }
                >
                  {selectedPermissions.includes(permission) && (
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  )}
                  {permission}
                </li>
              );
            }
          })}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
