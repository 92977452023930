import axios from "axios";
import { getHeader } from "../../utils/utils";
require("dotenv").config();
const NODEGROUP_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/node_groups/";

class nodeGroupService {
  getNodeGroupList(token) {
    return axios.get(NODEGROUP_URL, {
      headers: getHeader(token),
    });
  }
  createNewNodeGroup(token, name, tags) {
    return axios({
      method: "post",
      url: NODEGROUP_URL,
      headers: getHeader(token),
      data: {
        name: name,
        tags: tags,
      },
    });
  }

  deleteNodeGroup(token, id) {
    return axios({
      method: "delete",
      url: NODEGROUP_URL + id,
      headers: getHeader(token),
      data: {},
    });
  }

  addNodeToGroup(token, groupid, nodeid) {
    return axios({
      method: "post",
      url: NODEGROUP_URL + groupid + "/nodes/" + nodeid,
      headers: getHeader(token),
      data: {},
    });
  }

  removeNodeFromGroup(token, groupid, nodeid) {
    return axios({
      method: "delete",
      url: NODEGROUP_URL + groupid + "/nodes/" + nodeid,
      headers: getHeader(token),
      data: {},
    });
  }

  editNodeGroup(token, id, name, tags) {
    return axios({
      method: "patch",
      url: NODEGROUP_URL + id,
      headers: getHeader(token),
      data: {
        name: name,
        tags: tags,
      },
    });
  }
}

export default new nodeGroupService();
