import axios from "axios";
import md5 from "md5";
import { getHeader, lsDecrypt } from "../../utils/utils";

require("dotenv").config();
// axios.defaults.withCredentials = true;
const API_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/";
// axios.defaults.withCredentials = true;
// axios.defaults.headers.common["ngrok-skip-browser-warning"] = "any value";

class AuthService {
  login(email, password, token) {
    let md5Password = md5(password);
    // axios.defaults.headers.post["anti-csrf-token"] = "1234567890";

    return axios({
      method: "post",
      url: API_URL + "login",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      // xsrfCookieName: "XSRF-TOKEN", // default
      // xsrfHeaderName: "X-XSRF-TOKEN", // default
      data: {
        email: email,
        password: md5Password,
        token: token,
      },
    });
  }

  logout(token) {
    return axios({
      method: "post",
      url: API_URL + "logout",
      headers: getHeader(token, { ContentType: "multipart/form-data" }),
      data: {},
    });
  }

  register(username, email, password, role) {
    return axios.post(API_URL + "/register", {
      username,
      email,
      password,
      role,
    });
  }

  getCurrentUser() {
    return JSON.parse(lsDecrypt("user"));
  }
}

export default new AuthService();
