import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Permissions } from "../../utils/constants";
import {
  checkUserLogin,
  handleError,
  lsDecrypt,
  permissionWarnings,
  removeItems,
} from "../../utils/utils";
import OtherService from "../service/other.service";
import TokenService from "../service/token.service";
import UserService from "../service/user.service";
import AddNewUserGroupComponent from "./AddNewUserGroup-component";
import AddUserToGroupComponent from "./AddUserToGroup-component";
import EditUserGroupModal from "./EditUserGroupModal";
import ModifyUserNodeGroup from "./ModifyUserNodeGroup";

const ManageUserGroup = () => {
  const [activeModal, setActiveModal] = useState(false);
  const [userOrg, setUserOrg] = useState("");
  const [groupList, setGroupList] = useState("");
  const [initialData, SetInitialData] = useState(false);
  const [allUserList, setAllUserList] = useState("");
  //set for setManager
  const [openAddUserToGroup, setOpenAddUserToGroup] = useState(false);
  //from edit select
  const [activeNodeGroupWindow, setActiveNodeGroupWindow] = useState(false);
  const [openModifyNodeGroup, setOpenModifyNodeGroup] = useState(false);
  const [userGroupsNodeGroup, setUserGroupsNodeGroup] = useState([]);
  const [selectUserGroupID, setSelectUserGroupID] = useState("");
  const [selectUserGroupName, setSelectUserGroupName] = useState("");
  const [largeModal, setLargeModal] = useState(false);
  const [newGroupModal, setNewGroupModal] = useState(false);
  const [belongGroup, setBelongGroup] = useState([]);
  const [selectUserGroup, setSelectUserGroup] = useState(null);
  const [selectGroupMembers, setSelectGroupMembers] = useState([]);
  const permissionToRemove = ["manage node groups", "manage user groups"];
  const [currentDate, setCurrentDate] = useState("");
  //find the user is in admin group, and store the gorup which is manager
  const [managerList, setManagerList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialUserList, setInitialUserList] = useState([]);
  const { handleSubmit } = useForm();
  const history = useHistory();

  //initial setup and getting user's organization
  useEffect(() => {
    var addNav = document.querySelector("#nav-display").classList;
    addNav.remove("nav-display");
    var addLayout = document.querySelector("#content-body").classList;
    addLayout.add("content-body");
    var addLayout2 = document.querySelector("#container-fluid").classList;
    addLayout2.add("container-fluid");

    if (checkUserLogin(history)) {
      // let permission = localStorage.getItem("userPermission");
      let permission = lsDecrypt("userPermission");

      if (
        !permission.includes(Permissions.MANAGE_USER_GROUPS) &&
        !JSON.parse(localStorage.getItem("isManager"))
      ) {
        permissionWarnings(history);
      }

      if (permission.includes(Permissions.MANAGE_USER_GROUPS)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }

      const handleGetMe = () => {
        UserService.getMe(TokenService.getToken())
          .then((response) => {
            let data = JSON.stringify(response.data.belongsOrgGroup);
            let orgName = JSON.parse(data).name;
            setUserOrg(orgName);
            setBelongGroup(response.data.belongsOrgGroup.groups);
          })
          .catch((error) => {
            console.log(error.response);
            handleError(error, history, () => {
              handleGetMe();
            });
          });
      };
      handleGetMe();
      getUserGroup();
      getUserList();

      const handleGetPermission = () => {
        OtherService.getPermissionsList(TokenService.getToken())
          .then((response) => {
            let permissionlist = JSON.stringify(response.data.permissions);
            const filteredList = removeItems(
              JSON.parse(permissionlist).reverse(),
              permissionToRemove
            );
            setPermissionsList(filteredList);
          })
          .catch((error) => {
            console.log(error.response);
            handleError(error, history, () => {
              handleGetPermission();
            });
          });
      };

      handleGetPermission();

      getCurrentDate();
    }
  }, []);

  //get current time for temp display, can add the update group data time in the database in the future
  const getCurrentDate = () => {
    const date = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const formattedDate = `${date.getDate()} ${
      monthNames[date.getMonth()]
    } ${date.getFullYear()}, ${date.getHours() % 12 || 12}:${(
      "0" + date.getMinutes()
    ).slice(-2)} ${date.getHours() >= 12 ? "PM" : "AM"}`;

    setCurrentDate(formattedDate);
  };

  useEffect(() => {
    let tempManagerList = [];
    // let permission = localStorage.getItem("userPermission");
    let permission = lsDecrypt("userPermission");

    if (permission.includes(Permissions.MANAGE_USER_GROUPS)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    let activeGroupId = JSON.parse(localStorage.getItem("userGroup"));
    // let activeGroupId = JSON.parse(lsDecrypt("userGroup"));

    if (belongGroup !== undefined || belongGroup.length > 0) {
      for (let i = 0; i < belongGroup.length; i++) {
        //now change to only show the active group
        if (belongGroup[i]._id === activeGroupId) {
          tempManagerList.push(belongGroup[i]._id);
        }
      }
    }

    setManagerList(tempManagerList);
  }, [belongGroup]);

  //once user get org, get the group under that org (Common)
  useEffect(() => {
    // getUserGroup();
  }, [userOrg]);

  const getUserGroup = () => {
    UserService.getAllUserGroup(TokenService.getToken())
      .then((response) => {
        console.log(response);
        let group = JSON.stringify(response.data.groups);
        setGroupList(JSON.parse(group));
        SetInitialData(true);
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          getUserGroup();
        });
      });
  };

  const getUserList = () => {
    UserService.getUserList(TokenService.getToken())
      .then((response) => {
        console.log(response);
        let userlist = JSON.stringify(response.data.users);
        setAllUserList(JSON.parse(userlist));
      })

      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          getUserList();
        });
      });
  };

  useEffect(() => {
    if (selectUserGroup !== null) {
      habdleGetGroupMembers(selectUserGroup._id);
    }
  }, [selectUserGroup]);

  const habdleGetGroupMembers = (id) => {
    UserService.getUserGroupMember(TokenService.getToken(), id)
      .then((response) => {
        console.log(response.data);
        setSelectGroupMembers(response.data);
      })

      .catch((error) => {
        console.log(error);
        handleError(error, history, () => {
          habdleGetGroupMembers(id);
        });
      });
  };

  //for edit group complete
  const handleReloadPage = () => {
    getUserGroup();
  };

  useEffect(() => {
    if (selectGroupMembers.members) {
      // console.log("Get Group Member Permissions!");

      const getPromiss = selectGroupMembers.members.map((rowIndex) => {
        return UserService.getGroupMemberPermissions(
          TokenService.getToken(),
          selectUserGroup._id,
          rowIndex._id
        );
      });

      Promise.all(getPromiss)
        .then((response) => {
          // console.log(response);
          response.forEach((result, index) => {
            // console.log(
            //   `Result for getGroupMemberPermissions ${index + 1}:`,
            //   result
            // );
            selectGroupMembers.members[index]["permissions"] =
              result.data.permissions;

            //compare the manager id with the member id, if the same, set isManager to true
            const managerIds = new Set(
              selectGroupMembers.managers.map((manager) => manager._id)
            );

            selectGroupMembers.members = selectGroupMembers.members.map(
              (member) => {
                return {
                  ...member,
                  isManager: managerIds.has(member._id),
                };
              }
            );
          });

          setInitialUserList(
            JSON.parse(JSON.stringify(selectGroupMembers.members))
          );
          setLargeModal(true);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [selectGroupMembers]);

  //handle open create new group window
  const handleAddNewGroupWindow = (e) => {
    setNewGroupModal(true);
  };

  const handlOpenAddUserToGroup = (e) => {
    setOpenAddUserToGroup(true);
    setActiveModal(true);
  };

  const handleNodeGroup = (name, _id) => {
    setSelectUserGroupName(name);
    setSelectUserGroupID(_id);
    UserService.getUserGroupsNodeGroup(TokenService.getToken(), _id)
      .then((response) => {
        console.log(response.data.groups);
        let group = JSON.parse(JSON.stringify(response.data.groups));
        setUserGroupsNodeGroup(group);
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          handleNodeGroup();
        });
      });
  };

  useEffect(() => {
    if (selectUserGroupID !== "") {
      setOpenModifyNodeGroup(true);
      setActiveNodeGroupWindow(true);
    }
  }, [userGroupsNodeGroup]);

  const useListHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="mr-auto pr-3 mr-5">
          <h4 className="header-title mt-2 ml-4 fs-20">User Group Board</h4>
        </div>
        <div className="vl mr-3 ml-4"></div>

        {isAdmin && (
          <div id="container" className="mb-5">
            <span
              className="avatar-icon-1 mr-3"
              style={{ position: "absolute" }}
            >
              CN
            </span>
            <span
              className="avatar-icon-2 mr-3"
              style={{ position: "absolute" }}
            >
              CN
            </span>
            <span
              className="avatar-icon-3 mr-3"
              style={{ position: "absolute" }}
            >
              CN
            </span>
            <span className="avatar-icon-4" style={{ position: "absolute" }}>
              ALL USERS
            </span>
            <Link
              className="avatar-icon-5"
              to="/user-list"
              aria-expanded="false"
              style={{ position: "absolute" }}
            >
              <i className="fa fa-chevron-right avatar-icon-5-color"></i>
            </Link>
          </div>
        )}
      </div>
    );
  };

  const userJoinPendingBtn = () => {
    return (
      <Link to="/userjoin-pending">
        <Button className="btn btn-primary mb-1 mr-1 mt-2 ml-3 group-button-text">
          <svg
            width={25}
            height={25}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="btn-icon"
          >
            <path
              d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z"
              fill="#FFFFFF"
            />
          </svg>
          User Join Pending
        </Button>
      </Link>
    );
  };

  const addUseToGroupBtn = () => {
    return (
      <Button
        as="a"
        className="btn btn-primary mb-1 mr-3 mt-2 ml-3 group-button-text"
        onClick={() => handlOpenAddUserToGroup()}
      >
        <svg
          width={25}
          height={25}
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="btn-icon"
        >
          <path
            d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
            fill="#FFFFFF"
          />
          <path
            fillRule="evenodd"
            d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            fill="#FFFFFF"
          />
        </svg>
        Add User To Existing Group
      </Button>
    );
  };

  const addNewGroupBtn = () => {
    return (
      <Button
        as="a"
        to="/manage-org"
        className="btn btn-primary mb-1 mr-3 mt-2 group-button-text"
        data-toggle="modal"
        data-target="#addOrg"
        onClick={() => handleAddNewGroupWindow()}
      >
        <svg
          width={25}
          height={25}
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="btn-icon"
        >
          <path
            d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
            fill="#FFFFFF"
          />
        </svg>
        Add New Group
      </Button>
    );
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Manage User Group - NMC Web Monitor</title>
          <meta name="description" content="Manage user group" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>

      <li className="dz-chat-user" key=""></li>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block pb-3 border-0">
                  {useListHeader()}
                  <div className="btn-toolbar ">
                    <div className="d-flex align-items-right">
                      {isAdmin && userJoinPendingBtn()}
                      {isAdmin && addUseToGroupBtn()}
                      {isAdmin && addNewGroupBtn()}
                    </div>

                    {openAddUserToGroup && (
                      <AddUserToGroupComponent
                        userGroupActiveModal={[activeModal, setActiveModal]}
                        userOrgData={[userOrg, setUserOrg]}
                        userGroupData={[groupList, setGroupList]}
                        allUserListData={[allUserList, setAllUserList]}
                        token={TokenService.getToken()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* group card */}
        {userOrg !== "" && (
          <div className="col-lg-12">
            <div className="card">
              <div
                className="card-body card-bodybar card-padding"
                style={{ flex: "unset" }}
              >
                <div
                  className="fs-18 card-headertext-1 "
                  style={{ color: "#ffffff" }}
                >
                  {userOrg}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="col-lg-12">
          <div>
            <Row>
              {initialData &&
                groupList.map(
                  (group) =>
                    (managerList.includes(group._id) || isAdmin) && (
                      <Col lg="2" key={group._id}>
                        <Card className=" border-0 manage-user-card">
                          <Card.Header className=" border-0 pt-3 pb-0 card-header-text">
                            <Card.Title className="ml-4">
                              {group.name}
                            </Card.Title>

                            <div
                              style={{
                                visibility:
                                  managerList.includes(group._id) || isAdmin
                                    ? ""
                                    : "hidden",
                              }}
                            >
                              {" "}
                              <button
                                className="edit-btn ml-2 mb-1"
                                onClick={(e) => setSelectUserGroup(group)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="13"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 11 16"
                                >
                                  <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                                    fill="#000000"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    fill="#000000"
                                  />
                                </svg>
                              </button>
                            </div>
                          </Card.Header>
                          <Card.Body className="card-body-align">
                            <Card.Text className="card-body-align text-muted">
                              28 April 2023, 1:02 PM
                              {/* {currentDate} */}
                            </Card.Text>
                          </Card.Body>
                          <Card.Footer className=" border-0 pt-5">
                            {group.name !== "admin" && (
                              <Button
                                className={isAdmin ? "" : "display-hidden"}
                                variant="success tp-btn-light card-node-group-btn"
                                onClick={(e) =>
                                  handleNodeGroup(group.name, group._id)
                                }
                              >
                                Node Groups...
                              </Button>
                            )}
                          </Card.Footer>
                        </Card>
                      </Col>
                    )
                )}
            </Row>
          </div>
        </div>
      </div>
      {openModifyNodeGroup && (
        <ModifyUserNodeGroup
          nodeGroupWindow={[activeNodeGroupWindow, setActiveNodeGroupWindow]}
          openModifyNode={[openModifyNodeGroup, setOpenModifyNodeGroup]}
          userGroupName={[selectUserGroupName, setSelectUserGroupName]}
          userGroupID={[selectUserGroupID, setSelectUserGroupID]}
          nodeGroupList={[userGroupsNodeGroup, setUserGroupsNodeGroup]}
        />
      )}

      {largeModal && (
        <EditUserGroupModal
          title={"Edit User Group"}
          userOrg={userOrg}
          selectedUserGroup={[selectUserGroup, setSelectUserGroup]}
          opnModal={[largeModal, setLargeModal]}
          allUserList={allUserList}
          selectGroupMembers={selectGroupMembers}
          allPermissions={permissionsList}
          handleReloadPage={handleReloadPage}
          isInAdminGroup={isAdmin}
        />
      )}

      {newGroupModal && (
        <AddNewUserGroupComponent
          userOrg={userOrg}
          opnModal={[newGroupModal, setNewGroupModal]}
          getUserGroup={getUserGroup}
          permissionsList={permissionsList}
        />
      )}
    </>
  );
};

export default ManageUserGroup;
