import React, { useEffect } from "react";
import NodeCard from "./NodeCard";

const NodeCardGroup = ({
  isReProvision,
  nodeCardList,
  mobileSelectNode,
  mobileSelectNodeList,
  cardSelecNodeIDList,
  setSelectNodeName,
  isReadyToReProvision,
  handleSelectNode,
}) => {
  useEffect(() => {
    // console.log(nodeCardList);
  }, [nodeCardList]);

  return (
    <div className="node-card-group">
      {" "}
      {nodeCardList.map((index) => (
        <div key={index.id}>
          <NodeCard
            isReProvision={isReProvision}
            key={index.id}
            node={index}
            mobileSelectNode={mobileSelectNode}
            mobileSelectNodeListData={mobileSelectNodeList}
            cardSelecNodeIDList={cardSelecNodeIDList}
            setSelectNodeName={setSelectNodeName}
          />
        </div>
      ))}
    </div>
  );
};

export default NodeCardGroup;
