import { MDBDatatable } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const UserTableAdmin = ({ userList, selectRow }) => {
  const [actionData, setActionData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = selectRow;

  useEffect(() => {
    setActionData({
      columns: [
        {
          label: "Email ",
          field: "email",
          width: 500,
        },
      ],
      rows: userList.map((row, order) => {
        return {
          ...row,
        };
      }),
    });
  }, []);

  useEffect(() => {
    if (actionData !== null) {
      setOpen(true);
    }
  }, [actionData]);

  useEffect(() => {
    setActionData({
      columns: [
        {
          label: "Email",
          field: "email",
          width: 500,
        },
      ],
      rows: userList.map((row) => {
        return {
          ...row,
        };
      }),
    });
  }, [userList]);

  return (
    <>
      {open && (
        <div
          className="admin-table"
          style={{ margin: "2.5rem 4rem 2rem 4rem" }}
        >
          <MDBDatatable
            searchlabel="Search"
            search
            searchTop
            multi
            selectable
            hover
            data={actionData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            advancedData
            entries={4}
            entriesOptions={[4, 8, 12]}
            format={(field: string, value: any) => {
              if (field === "email") {
                return {
                  fontSize: "15.5px",
                };
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default UserTableAdmin;
