import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import NavHader from "./NavHader";
import SideBar from "./SideBar";

const JobieNav = ({ title, login, reloadTest, load }) => {
  const [toggle, setToggle] = useState("");
  const [openSideBar, setOpenSideBar] = useState(true);
  const [userPermission, setUserPermissions] = useState([]);
  const location = useLocation();
  const onClick = (name) => setToggle(toggle === name ? "" : name);

  useEffect(() => {
    if (!openSideBar) {
      setOpenSideBar(true);
    }
  }, [openSideBar]);

  // set the user's permissions when the component mounts.
  useEffect(() => {
    // console.log(JSON.parse(localStorage.getItem("isRoot")));
    if (JSON.parse(localStorage.getItem("isRoot")) === true) {
      setUserPermissions("Root");
    } else {
      setUserPermissions([]);
    }
  }, []);

  return (
    <Fragment>
      <NavHader />
      {openSideBar && (
        <SideBar
          selectUserGroupPermissions={[userPermission, setUserPermissions]}
          location={location}
        />
      )}

      <Header
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onGroup={() => onClick("group")}
        login={login}
        setOpenSideBar={setOpenSideBar}
        selectUserGroupPermissions={[userPermission, setUserPermissions]}
        reloadTest={reloadTest}
        load={load}
      />
    </Fragment>
  );
};

export default JobieNav;
