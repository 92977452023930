import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";

function OffCanvasRight({
  isSmallScreen,
  host,
  event,
  online,
  handleCleanSearch,
  closeCanvas,
  bios,
  fwVer,
  os,
  location,
  suborg,
  org,
  inputStatus,
  filterLabelList,
  ...props
}) {
  //organization
  const [orgList, setOrgList] = useState(filterLabelList[0]);
  const [orgLabel, setOrgLabel] = org;
  //sub organization
  const [subOrgList, setSubOrgList] = useState(filterLabelList[1]);
  const [subOrgLabel, setSubOrgLabel] = suborg;
  //location
  const [locationList, setLocationList] = useState(filterLabelList[2]);
  const [locationLabel, setLocationLabel] = location;
  //os
  const [osList, setOSList] = useState(filterLabelList[3]);
  const [osLabel, setOSLabel] = os;

  //Firmware Version
  const [fwVerLabel, setFWVerLabel] = fwVer;
  const [fwVerList, setFWVerList] = useState(filterLabelList[4]);

  //Basic Input/Output System Version

  const [BIOSList, setBIOSList] = useState(filterLabelList[5]);
  const [BIOSLabel, setBIOSLabel] = bios;
  const [hostList, setHostList] = useState(["Agent ON", "Agent OFF"]);
  const [eventList, setEventList] = useState([
    "All",
    "ACPI Event",
    "Sensor Event",
  ]);
  const [onLineList, setOnlineList] = useState(["Online", "Offline"]);
  const [hostStatus, setHostStatus] = host;
  const [eventStatus, setEventStatus] = event;
  const [onlineStatus, setOnlineStatus] = online;
  const [dataInput, setDataInput] = inputStatus;

  const handleResetSearch = () => {
    setDataInput(false);
    handleCleanSearch("Reset");
  };

  const handleClose = () => closeCanvas();

  const setOrgValue = (e) => {
    setOrgLabel(e);
    setDataInput(true);
  };

  const setSubOrgValue = (e) => {
    setSubOrgLabel(e);
    setDataInput(true);
  };

  const setLocationValue = (e) => {
    setLocationLabel(e);
    setDataInput(true);
  };

  const setOSValue = (e) => {
    setOSLabel(e);
    setDataInput(true);
  };

  const setFWVerValue = (e) => {
    setFWVerLabel(e);
    setDataInput(true);
  };

  const setBIOSValue = (e) => {
    setBIOSLabel(e);
    setDataInput(true);
  };

  const setOnlineStatusValue = (e) => {
    setOnlineStatus(e);
    setDataInput(true);
  };

  const setEventStatusValue = (e) => {
    setEventStatus(e);
    setDataInput(true);
  };

  const setHostStatusValue = (e) => {
    setHostStatus(e);
    setDataInput(true);
  };

  useEffect(() => {
    // console.log(filterLabelList);
  }, []);

  return (
    <>
      <Offcanvas show={true} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton={!dataInput}>
          {dataInput && (
            <div className="cancvas-arrow" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="col-12">
            <div className="d-flex offcanvas-body-title-div ">
              <div className="offcanvas-body-title"> More Filter</div>
              <div
                className="offcanvas-reset"
                style={{ cursor: "pointer" }}
                onClick={handleResetSearch}
              >
                Reset All
              </div>
            </div>

            <div className="offCanvas-div">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="card-search-dropdown dropdown-basic"
                  title="Dropdown button"
                >
                  <div> {orgLabel} &nbsp; &nbsp;</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {orgList.map((index) => (
                    <Dropdown.Item
                      id={index}
                      onClick={() => setOrgValue(index)}
                      eventKey={index}
                    >
                      {index}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="card-search-dropdown dropdown-basic"
                  title="Dropdown button"
                >
                  <div> {subOrgLabel} &nbsp; &nbsp;</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {subOrgList.map((index) => (
                    <Dropdown.Item
                      id={index}
                      onClick={() => setSubOrgValue(index)}
                      eventKey={index}
                    >
                      {index}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="card-search-dropdown dropdown-basic"
                  title="Dropdown button"
                >
                  <div> {locationLabel} &nbsp; &nbsp;</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {locationList.map((index) => (
                    <Dropdown.Item
                      id={index}
                      onClick={() => setLocationValue(index)}
                      eventKey={index}
                    >
                      {index}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="card-search-dropdown dropdown-basic"
                  title="Dropdown button"
                >
                  <div> {osLabel} &nbsp; &nbsp;</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {osList.map((index) => (
                    <Dropdown.Item
                      id={index}
                      onClick={() => setOSValue(index)}
                      eventKey={index}
                    >
                      {index}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="card-search-dropdown dropdown-basic"
                  title="Dropdown button"
                >
                  <div> {fwVerLabel} &nbsp; &nbsp;</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {fwVerList.map((index) => (
                    <Dropdown.Item
                      id={index}
                      onClick={() => setFWVerValue(index)}
                      eventKey={index}
                    >
                      {index}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="card-search-dropdown dropdown-basic"
                  title="Dropdown button"
                >
                  <div> {BIOSLabel} &nbsp; &nbsp;</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {BIOSList.map((index) => (
                    <Dropdown.Item
                      id={index}
                      onClick={() => setBIOSValue(index)}
                      eventKey={index}
                    >
                      {index}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {isSmallScreen && (
                <div>
                  {" "}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="card-search-dropdown dropdown-basic"
                      title="Dropdown button"
                    >
                      <div> {hostStatus} &nbsp; &nbsp;</div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {hostList.map((index) => (
                        <Dropdown.Item
                          id={index}
                          onClick={() => setHostStatusValue(index)}
                          eventKey={index}
                        >
                          {index}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="card-search-dropdown dropdown-basic"
                      title="Dropdown button"
                    >
                      <div> {eventStatus} &nbsp; &nbsp;</div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {eventList.map((index) => (
                        <Dropdown.Item
                          id={index}
                          onClick={() => setEventStatusValue(index)}
                          eventKey={index}
                        >
                          {index}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="card-search-dropdown dropdown-basic"
                      title="Dropdown button"
                    >
                      <div> {onlineStatus} &nbsp; &nbsp;</div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {onLineList.map((index) => (
                        <Dropdown.Item
                          id={index}
                          onClick={() => setOnlineStatusValue(index)}
                          eventKey={index}
                        >
                          {index}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default OffCanvasRight;
