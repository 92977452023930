import axios from "axios";
import md5 from "md5";
import { getHeader } from "../../utils/utils";
require("dotenv").config();
const GETME_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/me";
const USERGROUP_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/user_groups/";
const GETUSERLIST_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/users/";
const GETUSERPENDING_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/users/pending";

const RESETPASSWORD_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/account";

const LOGINRESETPASSWORD_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/users/edit";

class userService {
  getMe(token) {
    return axios.get(GETME_URL, {
      withCredentials: true,
      // xsrfCookieName: "XSRF-TOKEN", // default
      // xsrfHeaderName: "X-XSRF-TOKEN", // default
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
      headers: getHeader(token),
    });
  }

  getUserGroupsNodeGroup(token, userGroupid) {
    return axios({
      method: "get",
      url: USERGROUP_URL + userGroupid + "/node_groups",
      headers: getHeader(token),
      data: {},
    });
  }

  getAllUserGroup(token) {
    return axios.get(USERGROUP_URL, {
      headers: getHeader(token),
    });
  }

  getUserList(token) {
    return axios.get(GETUSERLIST_URL, {
      headers: getHeader(token),
    });
  }

  getUser(token, userID) {
    return axios({
      method: "get",
      url: GETUSERLIST_URL + userID,
      headers: getHeader(token),
      data: {},
    });
  }

  getUserPendingList(token) {
    return axios({
      method: "get",
      url: GETUSERPENDING_URL,
      headers: getHeader(token),
      data: {},
    });
  }

  getUserGroupMember(token, id) {
    return axios({
      method: "get",
      url: USERGROUP_URL + id + "/members/",
      headers: getHeader(token),
      data: {},
    });
  }

  createNewGroup(token, name, permissions) {
    return axios({
      method: "post",
      url: USERGROUP_URL,
      headers: getHeader(token),
      data: {
        name: name,
        permissions: permissions,
      },
    });
  }

  addNodeGroupToUserGroup(token, userGroupID, nodeGroupID) {
    return axios({
      method: "post",
      url: USERGROUP_URL + userGroupID + "/node_groups/" + nodeGroupID,
      headers: getHeader(token),
      data: {},
    });
  }

  addUser(token, id, user, isManager) {
    return axios({
      method: "post",
      url: USERGROUP_URL + id + "/members/" + user,
      headers: getHeader(token),
      data: {
        isManager: isManager,
      },
    });
  }

  //For edit group
  addUserToGroup(token, id, userID, isManager) {
    return axios({
      method: "post",
      url: USERGROUP_URL + id + "/members/" + userID,
      headers: getHeader(token),
      data: {
        isManager: isManager,
      },
    });
  }

  createUser(token, groupId, email, password, isManager) {
    let md5Password = md5(password);
    return axios({
      method: "post",
      url: GETUSERLIST_URL,
      headers: getHeader(token),
      data: {
        email: email,
        password: md5Password,
        groupId: groupId,
        isManager: isManager,
      },
    });
  }

  editUserGroup(token, id, name, permissions) {
    return axios({
      method: "patch",
      url: USERGROUP_URL + id,
      headers: getHeader(token),
      data: {
        name: name,
        permissions: permissions,
      },
    });
  }

  getGroupMemberPermissions(token, groupid, memberId) {
    return axios({
      method: "get",
      url: USERGROUP_URL + groupid + "/members/" + memberId + "/permissions",
      headers: getHeader(token),
      data: {},
    });
  }

  addGroupMemberPermissions(token, groupid, memberId, permissions) {
    return axios({
      method: "post",
      url: USERGROUP_URL + groupid + "/members/" + memberId + "/permissions",
      headers: getHeader(token),
      data: {
        permissions: permissions,
      },
    });
  }

  editUserGroupStatus(token, groupid, memberId, isManager) {
    return axios({
      method: "patch",
      url: USERGROUP_URL + groupid + "/members",
      headers: getHeader(token),
      data: {
        members: [
          {
            _id: memberId,
            isManager: isManager,
          },
        ],
      },
    });
  }

  removeUserFromGroup(token, id, userID) {
    return axios({
      method: "delete",
      url: USERGROUP_URL + id + "/members/" + userID,
      headers: getHeader(token),
      data: {},
    });
  }

  removeNodeGroupFromUserGroup(token, userGroupId, nodeGroupId) {
    return axios({
      method: "delete",
      url: USERGROUP_URL + userGroupId + "/node_groups/" + nodeGroupId,
      headers: getHeader(token),
      data: {},
    });
  }

  resetPasswordLogin(token, oldPassword, newPassword) {
    return axios({
      method: "post",
      url: LOGINRESETPASSWORD_URL,
      headers: getHeader(token),
      data: {
        oldpassword: md5(oldPassword),
        newpassword: md5(newPassword),
      },
    });
  }

  resetUserPasswordEmail(email) {
    return axios({
      method: "post",
      url: RESETPASSWORD_URL,
      data: {
        email: email,
      },
    });
  }

  resetUserPassword(email, code, newpassword) {
    return axios({
      method: "patch",
      url: RESETPASSWORD_URL,
      data: {
        email: email,
        code: code,
        newpassword: md5(newpassword),
      },
    });
  }
}

export default new userService();
