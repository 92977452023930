import axios from "axios";
import { getHeader } from "../../utils/utils";
require("dotenv").config();

const CREATE_NODE_PROVISION_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/node_provision/create";

const NODE_PROVISION_URL =
  process.env.REACT_APP_PROTOCOL +
  process.env.REACT_APP_HOSTNAME +
  ":" +
  process.env.REACT_APP_API_PORT +
  "/node_provision/";

class ProvisionService {
  createNodeProvision(token, name, description) {
    return axios({
      method: "post",
      url: CREATE_NODE_PROVISION_URL,
      headers: getHeader(token),
      data: {
        alias_name: name,
        desc: description,
      },
    });
  }

  getNodeProvision(token, id) {
    return axios({
      method: "get",
      url: NODE_PROVISION_URL + id,
      headers: getHeader(token),
      data: {},
    });
  }

  setReprovisionPara(token, id, mode, time) {
    if (mode === "clear_immediate") {
      return axios({
        method: "put",
        url: NODE_PROVISION_URL + id + "/reprovision",
        headers: getHeader(token),
        data: {
          mode: mode,
          expired_date: "",
        },
      });
    } else {
      return axios({
        method: "put",
        url: NODE_PROVISION_URL + id + "/reprovision",
        headers: getHeader(token),
        data: {
          mode: mode,
          expired_date: time,
        },
      });
    }
  }

  signReProvisionFile(token, id, bodyFormData) {
    return axios({
      method: "post",
      url: NODE_PROVISION_URL + id + "/reprovision",
      headers: getHeader(token, { ContentType: "multipart/form-data" }),
      data: bodyFormData,
    });
  }

  uploadProvisionFile(token, id, bodyFormData) {
    return axios({
      method: "post",
      url: NODE_PROVISION_URL + id + "/provision",
      headers: getHeader(token, { ContentType: "multipart/form-data" }),
      data: bodyFormData,
    });
  }

  getProvisionFileList(token, id) {
    return axios({
      method: "get",
      url: NODE_PROVISION_URL + id + "/list",
      headers: getHeader(token),
      data: {},
    });
  }

  getProvisionFile(token, id, provisionId) {
    return axios({
      method: "get",
      url: NODE_PROVISION_URL + id + "/file/" + provisionId,
      headers: getHeader(token),
      responseType: "blob",
    });
  }
}

export default new ProvisionService();
