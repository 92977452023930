import React from "react";

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a
            href="https://www.nuvoton.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nuvoton
          </a>{" "}
          2021 /
          <a href="/license" target="_new" rel="noopener noreferrer">
            About License
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
