import React from "react";
import ReactDOM from "react-dom";
import { LiffProvider } from "react-liff";

import App from "./App";
import "./index.css";
require("dotenv").config();

const liffId = process.env.REACT_APP_LIFF_ID;

const MyApp = () => {
  const isLargeScreen = window.innerWidth > 600;
  if (isLargeScreen) {
    return <App />;
  } else {
    return (
      <LiffProvider liffId={liffId}>
        <App />
      </LiffProvider>
    );
  }
};

ReactDOM.render(<MyApp />, document.getElementById("root"));
