import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Permissions } from "../../utils/constants";
import { handleError } from "../../utils/utils";
import TokenService from "../service/token.service";
import UserService from "../service/user.service";
import xssFilterService from "../service/xssfilter.service";
//new user group component
const AddNewUserGroupComponent = ({
  userOrg,
  opnModal,
  getUserGroup,
  permissionsList,
}) => {
  const [largeModal, setLargeModal] = opnModal;
  const [groupName, setGroupName] = useState("");
  const [selectedItems, setSelectedItems] = useState(new Set());
  const history = useHistory();

  const handleCloseWindow = (e) => {
    setLargeModal(false);
  };

  //handle selection and deselection of permissions
  const toggleSelectedItem = (item) => {
    const newSelectedItems = new Set(selectedItems);
    if (selectedItems.has(item)) {
      if (
        item === Permissions.MANAGE_NODES_NORMAL_ACTION &&
        newSelectedItems.has(Permissions.MANAGE_NODES_CRITICAL_ACTION)
      ) {
        Swal.fire({
          icon: "warning",
          text: "With 'Manage Nodes Critical Action' permission, you can't remove 'Manage Nodes Normal Action' individually.",
        });
      } else {
        newSelectedItems.delete(item);
      }
    } else {
      if (
        item === Permissions.MANAGE_NODES_CRITICAL_ACTION &&
        !newSelectedItems.has(Permissions.MANAGE_NODES_NORMAL_ACTION)
      ) {
        newSelectedItems.add(Permissions.MANAGE_NODES_NORMAL_ACTION);
      }
      newSelectedItems.add(item);
    }
    setSelectedItems(newSelectedItems);
  };

  //handle creation of new group
  const handleCreateNewGroup = () => {
    // prevent empty input
    if (groupName === "" || [...selectedItems].length === 0) {
      swal(
        "Invalid Input!",
        "Please enter group name and select permissions.",
        "warning"
      );
      return;
    }

    UserService.createNewGroup(TokenService.getToken(), groupName, [
      ...selectedItems,
    ])
      .then((response) => {
        console.log(response);
        swal(
          "Group Created!",
          "The new group has been created successfully.",
          "success"
        ).then(() => {
          getUserGroup();
        });
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          handleCreateNewGroup();
        });
      });

    handleCloseWindow();
  };

  return (
    <Modal
      className="fade bd-example-modal-lg "
      show={largeModal}
      onHide={handleCloseWindow}
      size="lg"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">Add New Group</Modal.Title>

        <Button
          variant=""
          onClick={() => handleCloseWindow()}
          type="button"
          className="close"
        >
          <span>x</span>
        </Button>
      </Modal.Header>

      <Modal.Body className="card-header-tab">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="items">
            <div className="form-group  px-5 ">
              <label className="normal-text">Group Name*</label>
              <input
                onChange={(e) =>
                  setGroupName(xssFilterService.removeHtmlTags(e.target.value))
                }
                type="text"
                className="form-control mb-3"
                value={groupName}
                required
              />
            </div>
            <div className="permission-token">
              <label className="normal-text">Permissions*</label>
              <ul className="token-list">
                {permissionsList.map((item, index) => {
                  if (item === Permissions.MANAGE_NODES_CRITICAL_ACTION) {
                    return (
                      <li key={index} className="cri-button">
                        <button
                          onClick={() =>
                            toggleSelectedItem(
                              Permissions.MANAGE_NODES_CRITICAL_ACTION
                            )
                          }
                          className={`${
                            selectedItems.has(
                              Permissions.MANAGE_NODES_CRITICAL_ACTION
                            )
                              ? "selected"
                              : ""
                          }`}
                        >
                          manage nodes critical action
                        </button>

                        <button
                          onClick={() =>
                            toggleSelectedItem(
                              Permissions.MANAGE_NODES_NORMAL_ACTION
                            )
                          }
                          className={`${
                            selectedItems.has(
                              Permissions.MANAGE_NODES_NORMAL_ACTION
                            )
                              ? "selected"
                              : ""
                          }`}
                        >
                          manage nodes normal action
                        </button>
                      </li>
                    );
                  } else if (item === Permissions.MANAGE_NODES_NORMAL_ACTION) {
                  } else {
                    return (
                      <li
                        key={index}
                        onClick={() => toggleSelectedItem(item)}
                        className={selectedItems.has(item) ? "selected" : ""}
                      >
                        {item}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <Button
            className="btn btn-primary mr-4"
            style={{
              padding: "1rem 3rem",
            }}
            onClick={() => handleCreateNewGroup()}
          >
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewUserGroupComponent;
