import { ErrorTypes } from "../../utils/constants";
import TokenService from "./token.service";

require("dotenv").config();

class errorService {
  async refreshAuthToken() {
    try {
      const response = await TokenService.refreshToken();
      TokenService.updateToken(response.data.token);
      return "Refresh token success";
    } catch (error) {
      if (
        error.response.status === 401 &&
        (error.response.data.message === "jwt can not be refreshed" ||
          error.response.data.message === "Refresh token is null.")
      ) {
        console.log("Can not refresh token, redirect to login page");
        return "Session expired";
      }
    }
    return "";
  }

  async handleResponseError(error) {
    console.log(error);

    if (error.response.status === 401) {
      const errorMessage =
        error.response.data instanceof Blob
          ? JSON.parse(await error.response.data.text()).message
          : error.response.data.message;

      //Error 401, but without error message, it means that jwt token and refresh token are both expired
      if (
        error.response.statusText === "Unauthorized" &&
        errorMessage === undefined
      ) {
        return "Session expired";
      }

      if (
        errorMessage === "JWT expired" ||
        errorMessage === "Not found in database" ||
        errorMessage === ""
      ) {
        return await this.refreshAuthToken();
      } else if (
        errorMessage === "User logout" ||
        errorMessage === "jwt malformed"
      ) {
        return "Not Login";
      } else if (
        errorMessage === "Refresh Token Expired" ||
        errorMessage === "Refresh token is null."
      ) {
        return "Session expired";
      }
      // else {
      //   return "Session expired";
      // }
    } else if (error.response.status === 409) {
      //file duplicate
      return "Duplicate.";
    } else if (error.response.status === 400) {
      console.log(error.response.data.message);
      console.log(
        error.response.data.message === ErrorTypes.CONCURRENT_ACTION_ERROR
      );
      if (error.response.data.message === "Verify signature failed.") {
        return "Verify signature failed.";
      } else if (error.response.data.message === "Agent info not found.") {
        return "Agent Empty";
      } else if (
        error.response.data.message === ErrorTypes.CONCURRENT_ACTION_ERROR
      ) {
        return "Action Unavailable Error";
      }
    } else if (error.response.status === 500) {
      const errorMessage =
        error.response.data instanceof Blob
          ? JSON.parse(await error.response.data.text()).message
          : error.response.data.message;

      if (errorMessage === "jwt malformed") {
        return "Session expired";
      }
    }
    return "";
  }
}

export default new errorService();
